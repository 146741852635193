import React from "react";
import "./Adrexx.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function StudentAdress() {
  return (
    <div>
      <div className="bg-exlpor">
        <br></br>
        <br></br>
        <div className="new-create1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Link to="/studentsp" style={{ textDecoration: "none" }}>
            <p className="back-tocourse1">Back to Course List</p>
          </Link>
        </div>
        <br></br>
        <p className="explor">ALEX HALES</p>
        <br></br>
        <div className="nav-explor">
          <p>
            <Link className="nav-explor" to="/studentportfolio">
              ART
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/tattos">
              TATTOOS
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/bodypercong">
              BODY PIERCING
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/studentabout">
              ABOUT
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/studentblog">
              BLOG
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/sutdentadress">
              CONTACT
            </Link>
          </p>
        </div>
        <div className="line">
          <hr style={{ color: "white" }}></hr>
        </div>
        <div className="icon-expo">
          <p style={{ color: "white" }}>
            <FontAwesomeIcon
              className="fa"
              icon={faShare}
              style={{ color: "white" }}
            />{" "}
            Share
          </p>
          <p style={{ color: "white" }}>
            <FontAwesomeIcon
              className="fa"
              icon={faPenToSquare}
              style={{ color: "white" }}
            />{" "}
            Edit Portfolio
          </p>
        </div>
        <hr style={{ color: "#FFFFFF66" }}></hr>
        <br></br>

        <div className="info-form">
          <form className="form-design">
            <br></br>
            <h5 style={{ color: "white", margin: "10px" }}>Contact</h5>
            <h1 style={{ color: "white", margin: "10px" }}>Get In Touch</h1>
            <label style={{ color: "white", margin: "10px" }}>Your Name</label>
            <br></br>
            <input type="text" className="regis" />
            <br></br>

            <label style={{ color: "white", margin: "10px" }}>
              Your E-mail
            </label>
            <br></br>
            <input type="text" className="regis" />
            <br></br>
            <label style={{ color: "white", margin: "10px" }}>
              Your Message(optional)
            </label>
            <br></br>
            <textarea
              rows="4"
              cols="27"
              name="comment"
              form="usrform"
              style={{
                margin: "10px",
                background: "#FF912C",
                color: "white",
                border: "0.5px solid white",
                padding: "5px",
              }}
              className="text-area2"
            >
              {" "}
            </textarea>
            <br></br>
            <button className="form-button">Submit</button>
          </form>
        </div>
        <br></br>
      </div>
    </div>
  );
}
