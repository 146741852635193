import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import IMG from "../assets/Frame 3669.png";
import FRAME from "../assets/Frame 3619.png";
import Profilepic from "../assets/Ellipse 3.svg";
import "./fold.css";
import { Link } from "react-router-dom";

function Folder() {
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="body-background">
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="data">
                <img src={LOGO} />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="container">
                <div className="div-style">
                  <div className="data-flec">
                    <div className="name">
                      <h2 className="welcome-name">
                        Welcome, Steve
                        <span className="description">
                          Lorem Ipsum sit amet dolor metier consecture{" "}
                        </span>
                      </h2>
                    </div>
                    <div className="icons-flex">
                      <div className="icons">
                        <div className="drop-down">
                          <div class="dropdown">
                            <svg
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="36"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="app-svg"
                            >
                              <g clip-path="url(#clip0_340_447)">
                                <path
                                  d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2H15.73C14.14 2 13.5 2.64 13.5 4.23V8.27C13.5 9.86 14.14 10.5 15.73 10.5H19.77C21.36 10.5 22 9.86 22 8.27Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.5 17.5H20.5"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M17.5 20.5V14.5"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_340_447">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>

                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item courses">Courses</a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Course</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Quiz</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Manuals</p>
                                  </div>
                                </a>
                              </li>
                              <hr></hr>
                              <li>
                                <a class="dropdown-item courses">Users</a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">Add New User</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">
                                      Create New Group
                                    </p>
                                  </div>
                                </a>
                              </li>
                              <hr></hr>
                              <li>
                                <a class="dropdown-item courses">
                                  Files & Folders
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">Upload File</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">
                                      Create New Folders
                                    </p>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="bell-icons">
                        <div className="drop-down">
                          <div class="dropdown">
                            <svg
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              xmlns="http://www.w3.org/2000/svg"
                              width="39"
                              height="38"
                              viewBox="0 0 39 38"
                              fill="none"
                            >
                              <path
                                d="M30.1769 21.2334L28.0082 19.0647V16.1918C28.0058 14.2064 27.2672 12.2925 25.9354 10.8201C24.6036 9.34776 22.7731 8.42149 20.798 8.22046V6.57812H19.1957V8.22046C17.2206 8.42149 15.3901 9.34776 14.0583 10.8201C12.7265 12.2925 11.9879 14.2064 11.9855 16.1918V19.0647L9.81677 21.2334C9.66651 21.3836 9.58208 21.5873 9.58203 21.7998V24.2032C9.58203 24.4157 9.66644 24.6194 9.81668 24.7697C9.96692 24.9199 10.1707 25.0043 10.3832 25.0043H15.9911V25.6268C15.9737 26.6432 16.332 27.6302 16.9972 28.3988C17.6625 29.1674 18.5879 29.6635 19.5963 29.7919C20.1532 29.8472 20.7155 29.7852 21.2471 29.6101C21.7787 29.4349 22.2677 29.1505 22.6827 28.775C23.0977 28.3995 23.4295 27.9413 23.6569 27.4299C23.8842 26.9185 24.0019 26.3651 24.0025 25.8055V25.0043H29.6105C29.823 25.0043 30.0268 24.9199 30.177 24.7697C30.3273 24.6194 30.4117 24.4157 30.4117 24.2032V21.7998C30.4116 21.5873 30.3272 21.3836 30.1769 21.2334ZM22.4003 25.8055C22.4003 26.4429 22.147 27.0542 21.6963 27.5049C21.2456 27.9557 20.6343 28.2089 19.9968 28.2089C19.3594 28.2089 18.7481 27.9557 18.2974 27.5049C17.8466 27.0542 17.5934 26.4429 17.5934 25.8055V25.0043H22.4003V25.8055ZM28.8094 23.4021H11.1843V22.1314L13.353 19.9628C13.5032 19.8126 13.5877 19.6088 13.5877 19.3964V16.1918C13.5877 14.492 14.263 12.8618 15.4649 11.6599C16.6669 10.4579 18.297 9.78268 19.9968 9.78268C21.6966 9.78268 23.3268 10.4579 24.5288 11.6599C25.7307 12.8618 26.406 14.492 26.406 16.1918V19.3964C26.406 19.6088 26.4904 19.8126 26.6407 19.9628L28.8094 22.1314V23.4021Z"
                                fill="#283C55"
                                fill-opacity="0.7"
                              />
                              <rect
                                x="1.08657"
                                y="0.274074"
                                width="36.7259"
                                height="36.7259"
                                rx="1.91852"
                                stroke="#283C55"
                                stroke-opacity="0.5"
                                stroke-width="0.548148"
                              />
                            </svg>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item bell1" href="#">
                                  <i class="fa-regular fa-bell"></i>
                                  &nbsp;Notifications
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <div style={{ display: "flex" }}>
                                    <p className="noti-profile"></p>
                                    &nbsp;&nbsp; <p>new post</p>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="#">
                                  <div style={{ display: "flex" }}>
                                    <p className="noti-profile"></p>
                                    &nbsp;&nbsp; <p>new post</p>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item onhover" href="#">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View All
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="search-bar">
                        <Popup
                          trigger={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                              fill="none"
                            >
                              <path
                                d="M29.2294 27.4708L23.7172 21.9586C25.0419 20.3684 25.7024 18.3287 25.5614 16.2639C25.4205 14.199 24.4889 12.268 22.9605 10.8726C21.4321 9.47709 19.4245 8.72459 17.3554 8.77161C15.2863 8.81862 13.315 9.66153 11.8515 11.125C10.3881 12.5884 9.54519 14.5598 9.49817 16.6289C9.45116 18.698 10.2037 20.7055 11.5991 22.234C12.9946 23.7624 14.9256 24.6939 16.9904 24.8349C19.0553 24.9758 21.0949 24.3153 22.6852 22.9907L28.1974 28.5029L29.2294 27.4708ZM10.982 16.8245C10.982 15.5253 11.3673 14.2552 12.0891 13.1749C12.8109 12.0946 13.8368 11.2527 15.0372 10.7555C16.2375 10.2583 17.5584 10.1282 18.8326 10.3817C20.1069 10.6351 21.2774 11.2608 22.1961 12.1795C23.1148 13.0982 23.7405 14.2687 23.9939 15.5429C24.2474 16.8172 24.1173 18.138 23.6201 19.3384C23.1229 20.5387 22.2809 21.5647 21.2007 22.2865C20.1204 23.0083 18.8503 23.3936 17.5511 23.3936C15.8094 23.3917 14.1397 22.6989 12.9082 21.4674C11.6766 20.2359 10.9839 18.5661 10.982 16.8245Z"
                                fill="#283C55"
                                fill-opacity="0.7"
                              />
                              <rect
                                x="1.00064"
                                y="0.274074"
                                width="36.7259"
                                height="36.7259"
                                rx="1.91852"
                                stroke="#283C55"
                                stroke-opacity="0.5"
                                stroke-width="0.548148"
                              />
                            </svg>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="popup-style">
                              <div>
                                <div>
                                  <div className="search">
                                    <i class="fa-solid fa-magnifying-glass sear"></i>
                                    <input
                                      type="search"
                                      className="searchstyle"
                                      placeholder="Search content, user, etc"
                                    />
                                    <i
                                      class="fa-solid fa-xmark sear"
                                      onClick={() => close()}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </div>
                                  <hr></hr>
                                  <h2 className="recent-result">
                                    Recent Result
                                  </h2>
                                  <div className="result">
                                    <a href="" className="result1">
                                      User
                                    </a>
                                    <a href="" className="result1">
                                      Content
                                    </a>
                                    <a href="" className="result1">
                                      Groups
                                    </a>
                                  </div>
                                  <hr></hr>
                                  <div className="srcoll">
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                      <p className="line">&nbsp;</p>
                      <div className="signup">
                        <div className="sign-img">
                          <img
                            src={Profilepic}
                            className="profilepic"
                            alt="Profile-Pic"
                          />
                        </div>
                        <div className="drop-down">
                          <div class="dropdown">
                            <a
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Steve Roe
                            </a>
                            <span className="admin-check">Admin</span>

                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item onhover" href="#">
                                  <i class="fa-regular fa-user"></i>&nbsp;My
                                  Profile
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="fa-light fa-arrow-right-to-bracket"></i>
                                  &nbsp;SignOut
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="leftnav">
                <button onClick={toggleUlVisibility}>
                  <FontAwesomeIcon
                    className="bars"
                    icon={faBars}
                    style={{ color: "#283C55" }}
                  />
                </button>
                <ul
                  className="leftnav-1"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <Link to="/welcomepage">
                      <FontAwesomeIcon
                        icon={faTableColumns}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Dashboard</h2>
                    </Link>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faInbox}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Inbox</h2>
                    </a>
                  </li>
                  <hr />
                  <li>
                    <Link to="/content" className="isactive">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Content</h2>
                    </Link>
                    <ul className="sub-tabs-list">
                      <li className="sub-tab">
                        <Link to="/content">Courses</Link>
                      </li>
                      <li className="sub-tab ">
                        <Link to="/quiz">Quiz</Link>
                      </li>
                      <li className="sub-tab">
                        <Link to="/addnewassignment">Assignments</Link>
                      </li>
                      <li className="sub-tab isactive1">
                        <Link to="/folder">Folders</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/studentsp">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Explore Student Portfolio</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentreg">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Users</h2>
                    </Link>
                  </li>
                </ul>
                <ul
                  className="leftnav-2"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Help</h2>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Settings</h2>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="container back">
                <div className="add-course">
                  <h2 className="totalcourse">
                    <span className="numbers1">28</span>&nbsp;Folders
                  </h2>

                  <div className="mmixer">
                    <div className="status">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_875_552)">
                          <path
                            d="M4 8L7 10.5L11 5.5M7.5 15C6.58075 15 5.6705 14.8189 4.82122 14.4672C3.97194 14.1154 3.20026 13.5998 2.55025 12.9497C1.90024 12.2997 1.38463 11.5281 1.03284 10.6788C0.68106 9.82951 0.5 8.91925 0.5 8C0.5 7.08075 0.68106 6.1705 1.03284 5.32122C1.38463 4.47194 1.90024 3.70026 2.55025 3.05025C3.20026 2.40024 3.97194 1.88463 4.82122 1.53284C5.6705 1.18106 6.58075 1 7.5 1C9.35652 1 11.137 1.7375 12.4497 3.05025C13.7625 4.36301 14.5 6.14348 14.5 8C14.5 9.85652 13.7625 11.637 12.4497 12.9497C11.137 14.2625 9.35652 15 7.5 15Z"
                            stroke="#283C55"
                            stroke-opacity="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_875_552">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <h2 className="all-status">&nbsp;All Status</h2>
                    </div>
                    <div className="filter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="10"
                        viewBox="0 0 16 10"
                        fill="none"
                        className="em"
                      >
                        <path
                          d="M5.25 8.9375C5.25 8.76345 5.31914 8.59653 5.44221 8.47346C5.56528 8.35039 5.7322 8.28125 5.90625 8.28125H9.84375C10.0178 8.28125 10.1847 8.35039 10.3078 8.47346C10.4309 8.59653 10.5 8.76345 10.5 8.9375C10.5 9.11155 10.4309 9.27847 10.3078 9.40154C10.1847 9.52461 10.0178 9.59375 9.84375 9.59375H5.90625C5.7322 9.59375 5.56528 9.52461 5.44221 9.40154C5.31914 9.27847 5.25 9.11155 5.25 8.9375ZM2.625 5C2.625 4.82595 2.69414 4.65903 2.81721 4.53596C2.94028 4.41289 3.1072 4.34375 3.28125 4.34375H12.4688C12.6428 4.34375 12.8097 4.41289 12.9328 4.53596C13.0559 4.65903 13.125 4.82595 13.125 5C13.125 5.17405 13.0559 5.34097 12.9328 5.46404C12.8097 5.58711 12.6428 5.65625 12.4688 5.65625H3.28125C3.1072 5.65625 2.94028 5.58711 2.81721 5.46404C2.69414 5.34097 2.625 5.17405 2.625 5ZM0 1.0625C0 0.888452 0.0691406 0.721532 0.192211 0.598461C0.315282 0.475391 0.482202 0.40625 0.65625 0.40625H15.0938C15.2678 0.40625 15.4347 0.475391 15.5578 0.598461C15.6809 0.721532 15.75 0.888452 15.75 1.0625C15.75 1.23655 15.6809 1.40347 15.5578 1.52654C15.4347 1.64961 15.2678 1.71875 15.0938 1.71875H0.65625C0.482202 1.71875 0.315282 1.64961 0.192211 1.52654C0.0691406 1.40347 0 1.23655 0 1.0625Z"
                          fill="#283C55"
                          fill-opacity="0.5"
                        />
                      </svg>
                      <h2 className="all-status">&nbsp;Filter</h2>
                    </div>
                    <div className="sortr">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        className="emr"
                      >
                        <path
                          d="M4 14H0L6 20V0H4V14ZM10 3V20H12V6H16L10 0V3Z"
                          fill="#283C55"
                          fill-opacity="0.5"
                        />
                      </svg>
                      <h2 className="all-status">&nbsp;Sort</h2>
                    </div>
                    <div className="bullet">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        className="ew1"
                      >
                        <path
                          d="M12.0013 26.6654H26.668C27.4013 26.6654 28.0293 26.404 28.552 25.8814C29.0746 25.3587 29.3355 24.7311 29.3346 23.9987V21.332H12.0013V26.6654ZM2.66797 10.6654H9.33464V5.33203H5.33464C4.6013 5.33203 3.9733 5.59337 3.45064 6.11603C2.92797 6.6387 2.66708 7.26626 2.66797 7.9987V10.6654ZM2.66797 18.6654H9.33464V13.332H2.66797V18.6654ZM5.33464 26.6654H9.33464V21.332H2.66797V23.9987C2.66797 24.732 2.9293 25.36 3.45197 25.8827C3.97464 26.4054 4.60219 26.6663 5.33464 26.6654ZM12.0013 18.6654H29.3346V13.332H12.0013V18.6654ZM12.0013 10.6654H29.3346V7.9987C29.3346 7.26537 29.0733 6.63737 28.5506 6.1147C28.028 5.59203 27.4004 5.33114 26.668 5.33203H12.0013V10.6654Z"
                          fill="#283C55"
                        />
                      </svg>
                    </div>
                    <div className="border-grid">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        className="ew"
                      >
                        <path
                          d="M1.75 4.375C1.75 3.67881 2.02656 3.01113 2.51884 2.51884C3.01113 2.02656 3.67881 1.75 4.375 1.75H9.625C10.3212 1.75 10.9889 2.02656 11.4812 2.51884C11.9734 3.01113 12.25 3.67881 12.25 4.375V9.625C12.25 10.3212 11.9734 10.9889 11.4812 11.4812C10.9889 11.9734 10.3212 12.25 9.625 12.25H4.375C3.67881 12.25 3.01113 11.9734 2.51884 11.4812C2.02656 10.9889 1.75 10.3212 1.75 9.625V4.375ZM15.75 4.375C15.75 3.67881 16.0266 3.01113 16.5188 2.51884C17.0111 2.02656 17.6788 1.75 18.375 1.75H23.625C24.3212 1.75 24.9889 2.02656 25.4812 2.51884C25.9734 3.01113 26.25 3.67881 26.25 4.375V9.625C26.25 10.3212 25.9734 10.9889 25.4812 11.4812C24.9889 11.9734 24.3212 12.25 23.625 12.25H18.375C17.6788 12.25 17.0111 11.9734 16.5188 11.4812C16.0266 10.9889 15.75 10.3212 15.75 9.625V4.375ZM1.75 18.375C1.75 17.6788 2.02656 17.0111 2.51884 16.5188C3.01113 16.0266 3.67881 15.75 4.375 15.75H9.625C10.3212 15.75 10.9889 16.0266 11.4812 16.5188C11.9734 17.0111 12.25 17.6788 12.25 18.375V23.625C12.25 24.3212 11.9734 24.9889 11.4812 25.4812C10.9889 25.9734 10.3212 26.25 9.625 26.25H4.375C3.67881 26.25 3.01113 25.9734 2.51884 25.4812C2.02656 24.9889 1.75 24.3212 1.75 23.625V18.375ZM15.75 18.375C15.75 17.6788 16.0266 17.0111 16.5188 16.5188C17.0111 16.0266 17.6788 15.75 18.375 15.75H23.625C24.3212 15.75 24.9889 16.0266 25.4812 16.5188C25.9734 17.0111 26.25 17.6788 26.25 18.375V23.625C26.25 24.3212 25.9734 24.9889 25.4812 25.4812C24.9889 25.9734 24.3212 26.25 23.625 26.25H18.375C17.6788 26.25 17.0111 25.9734 16.5188 25.4812C16.0266 24.9889 15.75 24.3212 15.75 23.625V18.375Z"
                          fill="#283C55"
                          fill-opacity="0.4"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="row no-gutter">
                  <div className="col-lg-4 col-md-5 col-sm-12 no-gutter">
                    <div className="pinned-section">
                      <div className="pinned-header">
                        {" "}
                        <FontAwesomeIcon
                          icon={faThumbTack}
                          style={{ color: "#283C5580" }}
                        />
                        <h2>Pinned (3)</h2>
                      </div>
                      <ul>
                        <li style={{ marginLeft: "-20px" }}>
                          {" "}
                          <a href="">
                            <FontAwesomeIcon
                              icon={faFolder}
                              style={{ color: "#283C5580" }}
                            />{" "}
                            Pinned folder
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 col-sm-12 no-gutter">
                    <div className="folders-header">
                      <h2>Folders (15)</h2>
                    </div>
                    <div className="folders-list">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 no-gutter">
                          <div className="cardp">
                            <div className="card-head">
                              {" "}
                              <input type="checkbox" className="checkbox" />
                              <div className="menu" onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faEllipsis} />
                                {isMenuOpen && (
                                  <div className="dropdown-menu">
                                    <ul className="folder-menu">
                                      <li>Option 1</li>
                                      <li>Option 2</li>
                                      <li>Option 3</li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <Link to="/folderclick">
                              <div>
                                <FontAwesomeIcon
                                  className="folder-icon"
                                  icon={faFolder}
                                  style={{ color: "#ff7a71" }}
                                />{" "}
                              </div>
                              <h3>Course Title 1</h3>
                              <h4>21 Files ,15 Folders</h4>
                            </Link>
                          </div>
                          <div className="dropdown"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Folder;
