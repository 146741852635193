import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import R from "../assets/Upload image.png";
import D from "../assets/Rectangle 125 (1).png";
import F from "../assets/Rectangle 125.png";
import G from "../assets/Rectangle 126.png";
import H from "../assets/Rectangle 127.png";
import J from "../assets/Rectangle 129.png";
import { Link } from "react-router-dom";
import "./Stuportfolio.css";
import Profilepic from "../assets/Ellipse 3.svg";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import IMG from "../assets/Frame 3669.png";

export default function Bodypercing() {
  return (
    <div className="bg-exlpor">
      <br></br>
      <br></br>

      <div className="new-create1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Link to="/studentsp" style={{ textDecoration: "none" }}>
          <p className="back-tocourse1">Back to Course List</p>
        </Link>
      </div>
      <br></br>
      <p className="explor">ALEX HALES</p>
      <br></br>
      <div className="nav-explor">
        <p>
          <Link className="nav-explor" to="/studentportfolio">
            ART
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/tattos">
            TATTOOS
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/bodypercong">
            BODY PIERCING
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/studentabout">
            ABOUT
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/studentblog">
            BLOG
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/sutdentadress">
            CONTACT
          </Link>
        </p>
      </div>
      <div className="line9">
        <hr style={{ color: "white" }}></hr>
      </div>
      <div className="icon-expo">
        <p style={{ color: "white" }}>
          <FontAwesomeIcon
            className="fa"
            icon={faShare}
            style={{ color: "white" }}
          />{" "}
          Share
        </p>
        <p style={{ color: "white" }}>
          <FontAwesomeIcon
            className="fa"
            icon={faPenToSquare}
            style={{ color: "white" }}
          />{" "}
          Edit Portfolio
        </p>
      </div>
      <hr style={{ color: "#FFFFFF66" }}></hr>
      <br></br>

      <div className="gallery-wrapper">
        <div className="gallery">
          <img src={R} className="img-responsive" alt="Image 1" />
          <img src={D} className="img-responsive" alt="Image 2" />
          <img src={F} className="img-responsive" alt="Image 3" />
          <img src={G} className="img-responsive" alt="Image 4" />
          <img src={H} className="img-responsive" alt="Image 5" />
          <img src={J} className="img-responsive" alt="Image 6" />
          <img
            src="https://source.unsplash.com/1200x1600?female,portrait"
            className="card img-responsive"
            alt="Image 7"
          />
          <img
            src="https://source.unsplash.com/800x600?female,portrait"
            className="img-responsive"
            alt="Image 8"
          />
          <img
            src="https://source.unsplash.com/600x800?female,portrait"
            className="img-responsive"
            alt="Image 9"
          />
          <img
            src="https://source.unsplash.com/400x600?female,portrait"
            className="img-responsive"
            alt="Image 10"
          />
          <img
            src="https://source.unsplash.com/600x400?female,portrait"
            className="img-responsive"
            alt="Image 11"
          />
          <img
            src="https://source.unsplash.com/1100x1600?female,portrait"
            className="img-responsive"
            alt="Image 12"
          />
          <img
            src="https://source.unsplash.com/1600x1100?female,portrait"
            className="img-responsive"
            alt="Image 13"
          />
          <img
            src="https://source.unsplash.com/992x768?female,portrait"
            className="img-responsive"
            alt="Image 14"
          />
          <img
            src="https://source.unsplash.com/768x992?female,portrait"
            className="img-responsive"
            alt="Image 15"
          />
        </div>
      </div>

      <br></br>
    </div>
  );
}
