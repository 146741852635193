import React, { useState, useEffect } from 'react'
import LOGO from "../assets/logo2.png";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faL } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Upernav from "./Upernav";
import "./help.css";

const FaqItem = ({ questionNumber, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFaq = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div>
                <div className="FQ">
                    <h2>{questionNumber}</h2>
                    <button onClick={toggleFaq}>
                        {isOpen ? '-' : '+'}
                    </button>
                </div>

            </div>
            {isOpen && <p>{answer}</p>}
        </div>
    );
};
function Help() {
    const [loading, setLoading] = useState(true);
    const [isUlVisible, setIsUlVisible] = useState(false);
    const toggleUlVisibility = () => {
        setIsUlVisible(!isUlVisible);
    };
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };
    useEffect(() => {
        setLoading(false)
    }, [])
    return (
        <div className="body-background">
            <div className="full-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="fixer">
                                <div className="data">
                                    <img src={LOGO} />
                                </div>
                                <div className="leftnav">
                                    <button onClick={toggleUlVisibility}>
                                        <FontAwesomeIcon
                                            className="bars"
                                            icon={faBars}
                                            style={{ color: "#283C55" }}
                                        />
                                    </button>
                                    <ul
                                        className="leftnav-1 padder"
                                        style={{ display: isUlVisible ? "none" : "block" }}
                                    >
                                        <li>
                                            <Link to="/welcomepage" >
                                                <FontAwesomeIcon
                                                    icon={faTableColumns}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Dashboard</h2>
                                            </Link>
                                        </li>
                                        <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                                        <hr />
                                        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link to="/content">
                                                <FontAwesomeIcon
                                                    icon={faClipboard}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Content</h2>
                                            </Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none" style={{ marginTop: '7px' }}>
                                                <path d="M15 1L8 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </li>
                                        <li>
                                            <Link to="/studentsp">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Explore Student Portfolio</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/studentreg">
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Users</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul
                                        className="leftnav-2 padder"
                                        style={{ display: isUlVisible ? "none" : "block", padding: '0px 10px' }}
                                    >
                                        <li>
                                            <Link to={'/help'} className="isactive">
                                                <FontAwesomeIcon
                                                    icon={faCircleInfo}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Help</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/settings'}>
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Settings</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className="div-style">
                                <div className="container">
                                    <Upernav />
                                </div>
                                <div className="container-fuild">
                                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                                </div>
                                {loading ? (
                                    <div className="kkw">
                                        <span class="loaders"></span>
                                    </div>
                                ) : (
                                    <div className="container back">
                                        <div className="faq">
                                            <h1>Frequently asked <span className='d-block'>questions</span> </h1>
                                            <h2>Do you need help with something or do <span className='d-block'>you have questions on some features?</span></h2>
                                            <div className="FAQ">
                                                <div className="FAQSS">
                                                    <div className="container">
                                                        <FaqItem
                                                            questionNumber={'What is the LMS System Admin Portal?'}
                                                            answer="The LMS System Admin Portal is a centralized platform designed for administrators to manage and oversee various aspects of the learning management system. It provides tools and functionalities for system configuration, user management, content management, reporting, and more."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                        <FaqItem
                                                            questionNumber={'How do I access the LMS System Admin Portal?'}
                                                            answer="Access to the LMS System Admin Portal is typically granted to designated system administrators by the LMS administrator or IT department. You can usually access it through a web browser using a specific URL provided by your organization."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                        <FaqItem
                                                            questionNumber={'What can I do as an administrator in the MTI System?'}
                                                            answer="As an administrator, you can perform various tasks such as managing user accounts, setting up courses and learning paths, configuring system settings,see chats of students or instructor and troubleshooting technical issues."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                        <FaqItem
                                                            questionNumber={'How do I add or remove users from the LMS?'}
                                                            answer="You can add or remove users through the users section of the admin portal. Typically, this involves creating new user accounts, and deleting accounts when necessary."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                        <FaqItem
                                                            questionNumber={'How do I create and manage courses and content?'}
                                                            answer="In the admin portal, you can create new courses, upload course content such as documents, videos, and quizzes, organize content into modules, and manage course enrollments for users."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                        <FaqItem
                                                            questionNumber={'How often should I perform maintenance tasks?'}
                                                            answer="Regular maintenance tasks such as user account management, content updates, system backups, and software updates should be performed according to your organization's policies and best practices to ensure the smooth functioning of the LMS."
                                                        />
                                                        <hr style={{ color: 'black', marginTop: '-13px' }} />
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Help