import React from "react";
import "./sabout.css";
import D from "../assets/Upload image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <div className="bg-exlpor">
        <br></br>
        <br></br>
        <div className="new-create1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Link to="/studentsp" style={{ textDecoration: "none" }}>
            <p className="back-tocourse1">Back to Course List</p>
          </Link>
        </div>
        <br></br>
        <p className="explor">ALEX HALES</p>
        <br></br>
        <div className="nav-explor">
          <p>
            <Link className="nav-explor" to="/studentportfolio">
              ART
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/tattos">
              TATTOOS
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/bodypercong">
              BODY PIERCING
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/studentabout">
              ABOUT
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/studentblog">
              BLOG
            </Link>
          </p>
          <p>
            <Link className="nav-explor" to="/sutdentadress">
              CONTACT
            </Link>
          </p>
        </div>
        <div className="line6">
          <hr style={{ color: "white" }}></hr>
        </div>
        <div className="icon-expo">
          <p style={{ color: "white" }}>
            <FontAwesomeIcon
              className="fa"
              icon={faShare}
              style={{ color: "white" }}
            />{" "}
            Share
          </p>
          <p style={{ color: "white" }}>
            <FontAwesomeIcon
              className="fa"
              icon={faPenToSquare}
              style={{ color: "white" }}
            />{" "}
            Edit Portfolio
          </p>
        </div>
        <hr style={{ color: "#FFFFFF66" }}></hr>
        <br></br>
        <div className="about-self">
          <div class="grid-thirds">
            <div class="container-9">
              <img src={D} className="oi" />
            </div>
            <div class="container-0">
              <div className="self-text">
                <p>Name : Alex</p>
                <p>Age: 34 years old</p>
                <p>Experience: 10 years</p>
                <p>Certification: MTI School</p>
              </div>
              <div className="self-des">
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
}
