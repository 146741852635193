import Welcomepage from "./components/Welcomepage";
import Content from "./components/Content";
import Cratecourse from "./components/Cratecourse";
import Weekscontent from "./components/Weekscontent";
import CoursePreview from "./components/CoursePreview";
import Statistic from "./components/Statistic";
import Students from "./components/Students";
import Quiz from "./components/Quiz";
import Addquiz from "./components/Addquiz";
import Addquestion from "./components/Addquestion";
import Quizgrade from "./components/Quizgrade";
import Quizperview from "./components/Quizpreview";
import Addnewquiz from "../src/components/Addnewquiz";
import Addnewassignment from "../src/components/Addnewassignmet";
import Assignmentperview from "./components/Assignmentperview";
import Studentporfolio from "./components/Studentportfolio";
import Studentabout from "../src/components/Studentabout";
import StudentAdress from "./components/StudentAdress";
import Folder from "./components/Folder";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Folderclick from "./components/Folderclick";
import Studentsp from "./components/Studentsp";
import Studentblog from "./components/Studentbolg";
import Bodypercing from "./components/Bodypercing";
import Tattos from "./components/Tattos";
import StudentReg from "./components/StudentReg";
import Addstudentreg from "./components/Addstudentreg";
import Addstudentmail from "./components/Addstudentemail";
import Studentprofiles from "./components/Studentprofiles";
import Studentcontent from "./components/Studentcontent";
import Teacherreg from "../src/components/Teacherreg";
import Addteacherreg from "./components/Addteacherreg";
import Teachermail from "./components/Teachermail";
import Teachersprofiles from "./components/Teachersprofiles";
import Groups from "./components/Groups";
import Login from "../src/components/Login";
import Forget from "../src/components/Forget"
import Newpassword from "./components/Newpassword";
import Otp from "./components/Otp";
import Upernav from "./components/Upernav";
import Editweeks from "./components/Editweeks";
import Editquestion from "./components/Editquestion";
import Settings from "./components/Settings";
import Help from "./components/Help";
import Inbox from "./components/Inbox";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/forget/otp" element={<Otp />} />
          <Route path="/forget/otp/newpassword" element={<Newpassword />} />
          <Route path="/welcomepage" element={<Welcomepage />} />
          <Route path="/content" element={<Content />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/addnewassignment" element={<Addnewassignment />} />
          <Route path="/folder" element={<Folder />} />
          <Route path="/createcourse" element={<Cratecourse />} />
          <Route path="/createc/:course" element={<Cratecourse />} />
          <Route path="/weekcontent/:courseId" element={<Weekscontent />} />
          <Route path="/editweeks/:courseId" element={<Editweeks />} />
          <Route path="/coursepreview/:courseId" element={<CoursePreview />} />
          <Route path="/statistic/:courseId" element={<Statistic />} />
          <Route path="/students/:courseId" element={<Students />} />
          <Route path="/assignmentperview/:courseId" element={<Assignmentperview />} />
          <Route path="/addquiz/:courseId" element={<Addquiz />} />
          <Route path="/addquestion/:quizId" element={<Addquestion />} />
          <Route path="/editquestion/:courseId" element={<Editquestion/>} />
          <Route path="/addnewquiz/:courseId" element={<Addnewquiz />} />
          <Route path="/quizperview/:quizId" element={<Quizperview />} />
          <Route path="/quizgrade/:quizId" element={<Quizgrade />} />
          <Route path="/folderclick" element={<Folderclick />} />
          <Route path="/studentsp" element={<Studentsp />} />
          <Route path="/studentportfolio" element={<Studentporfolio />} />
          <Route path="/studentabout" element={<Studentabout />} />
          <Route path="/sutdentadress" element={<StudentAdress />} />
          <Route path="/studentblog" element={<Studentblog />} />
          <Route path="/bodypercong" element={<Bodypercing />} />
          <Route path="/tattos" element={<Tattos />} />
          <Route path="/studentreg" element={<StudentReg />} />
          <Route path="/addstudentreg" element={<Addstudentreg />} />
          <Route path="/addstudentmail" element={<Addstudentmail />} />
          <Route path="/studentprofiles/:studentId" element={<Studentprofiles />} />
          <Route path="/studentcontent" element={<Studentcontent />} />
          <Route path="/teacherreg" element={<Teacherreg />} />
          <Route path="/addteacherreg" element={<Addteacherreg />} />
          <Route path="/teachermail" element={<Teachermail />} />
          <Route path="/teacherprofiles/:studentId" element={<Teachersprofiles />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/upernav" element={<Upernav />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<Help />} />
          <Route path="/inbox" element={<Inbox />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
