import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLeaf, faUser } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import img1 from "../assets/Frame 3619.png";
import { Link } from "react-router-dom";
import "./studentcontents.css";

function Studentcontents() {
  const [activeTab, setActiveTab] = useState("user");

  const showUserContent = () => {
    setActiveTab("user");
  };

  const showContent = () => {
    setActiveTab("content");
  };

  const showGroups = () => {
    setActiveTab("groups");
  };

  const [isUlVisible, setIsUlVisible] = useState(false);

  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container">
      <div className="row no-gutter">
        <div className="col-lg-3 col-md-3 col-sm-12 no-gutter">
          <div className="leftnav">
            <button onClick={toggleUlVisibility}>
              <FontAwesomeIcon
                className="bars"
                icon={faBars}
                style={{ color: "#283C55" }}
              />
            </button>
            <ul
              className="leftnav-1"
              style={{ display: isUlVisible ? "none" : "block" }}
            >
              <li>
                <Link to="/welcomepage" className="isactive">
                  <FontAwesomeIcon
                    icon={faTableColumns}
                    style={{ color: "#283C55", marginBottom: "10px" }}
                  />
                  <h2>Dashboard</h2>
                </Link>
              </li>
              <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
              <hr />
              <li>
                <Link to="/content">
                  <FontAwesomeIcon
                    icon={faClipboard}
                    style={{ color: "#283C55", marginBottom: "10px" }}
                  />
                  <h2>Content</h2>
                </Link>
              </li>
              <li>
                <Link to="/studentsp">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "#283C55" }}
                  />
                  <h2>Explore Student Portfolio</h2>
                </Link>
              </li>
              <li>
                <Link to="/studentreg">
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ color: "#283C55" }}
                  />
                  <h2>Users</h2>
                </Link>
              </li>
            </ul>
            <ul
              className="leftnav-2"
              style={{ display: isUlVisible ? "none" : "block" }}
            >

              <li>
                <Link to={'/help'}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ color: "#283C55" }}
                  />
                  <h2>Help</h2>
                </Link>
              </li>
              <li>
                <Link to={'/settings'}>
                  <FontAwesomeIcon
                    icon={faGear}
                    style={{ color: "#283C55" }}
                  />
                  <h2>Settings</h2>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 no-gutter">
          <div className="white-container">
            <div className="profile-header">
              <div className="profile-cover">
                <div className="profile-img">
                  <img src={img1} alt="" />
                </div>
              </div>
            </div>
            <div className="profile-name">
              <h2>Alex Hales</h2>
              <h3>Edit profile</h3>
            </div>
            <div className="profile-content">
              <div className="profile-tabs">
                <ul>
                  <li className={activeTab === "user" ? "active-tab" : ""}>
                    <a href="#" onClick={showUserContent}>
                      User Details
                    </a>
                  </li>
                  <li className={activeTab === "content" ? "active-tab" : ""}>
                    <a href="#" onClick={showContent}>
                      Content
                    </a>
                  </li>
                  <li className={activeTab === "groups" ? "active-tab" : ""}>
                    <a href="#" onClick={showGroups}>
                      Groups
                    </a>
                  </li>
                </ul>
              </div>
              <div style={{ display: activeTab === "user" ? "block" : "none" }}>
                <div className="user-details">
                  <h3>Personal Information</h3>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                      <h2>Full Name</h2> <h1>Alex hales</h1>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 no-gutter">
                      <h2>Registration Number</h2> <h1>87656756</h1>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                      <h2>Gender</h2> <h1>Male</h1>
                    </div>
                  </div>

                  <h3>Contact Information</h3>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                      <h2>Full Name</h2> <h1>Alex hales</h1>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 no-gutter">
                      <h2>Registration Number</h2> <h1>87656756</h1>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                      <h2>Gender</h2> <h1>Male</h1>
                    </div>
                  </div>
                  <h3>Address</h3>
                  <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-12 no-gutter">
                      <h2>Full Name</h2> <h1>Addresssssssssssssssssssss</h1>
                    </div>
                  </div>

                  <h3>Educational Information</h3>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                      <h2>Full Name</h2> <h1>-----------</h1>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 no-gutter">
                      <h2>Full Name</h2> <h1>-------------</h1>
                    </div>
                  </div>

                  <h3>Professional Information</h3>
                  <div className="row">
                    <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                      <h2>Role</h2> <h1>Artist</h1>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 no-gutter">
                      <h2>Skills</h2>{" "}
                      <div className="skills">
                        {" "}
                        <h2>Sketching</h2>
                        <h2>painting</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: activeTab === "content" ? "block" : "none" }}
              >
                <div className="content">
                  <div className="content-head">
                    <div>
                      <h2>3 Assigned courses</h2>
                    </div>
                    <div className="content-stats">
                      <div>
                        {" "}
                        <h3>Published</h3>
                      </div>
                      <div>
                        {" "}
                        <h2>Created on August 8, 2023</h2>
                      </div>
                      <div>
                        {" "}
                        <h2>Course</h2>
                      </div>
                    </div>
                  </div>

                  <div className="content-cards">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 no-gutter">
                        <div className="custom-card">
                          <div className="card-image">
                            <img src={img1} alt="Card Image" />
                          </div>
                          <div className="card-info-1">
                            <h3>Course Title - Intermediate</h3>
                            <div className="course-type">
                              <FontAwesomeIcon
                                icon={faUsers}
                                style={{ color: "#FFFFFF99" }}
                              />{" "}
                              <h2>27 Learners</h2>
                            </div>
                            <div className="course-type">
                              <FontAwesomeIcon
                                icon={faLeaf}
                                style={{ color: "#FFFFFF99" }}
                              />{" "}
                              <h2>Design</h2>
                            </div>
                            <div className="card-stats">
                              <div className="row">
                                <div className="col-6">
                                  <h2>Completion Rate</h2>
                                  <h3>68%</h3>
                                </div>
                                <div className="col-6">
                                  <h2>Student Reaction</h2>
                                  <h3>38%</h3>
                                </div>
                              </div>
                            </div>
                            <div className="course-card-button">
                              <button>Total Statistics</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: activeTab === "groups" ? "block" : "none" }}
              >
                <div className="profile-groups">
                  <div className="groups-table">
                    <div className="groups-table-heading">
                      <div className="row">
                        <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                          <h2>Group Name</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                          <h2>Total Members</h2>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                          <h2>Date Added</h2>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2" />
                    <div className="row">
                      <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                        <div className="group-name">
                          <div className="group-name-1">
                            <img src={img1} alt="" />
                          </div>
                          <div className="group-name-2">
                            <h2>Public</h2> <h3>Course Title</h3>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                        <div className="group-total-members">
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <div className="member-count">
                            <h2>+34</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                        <div className="group-date-added">
                          <h2>August 19, 2023</h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                        <div className="group-name">
                          <div className="group-name-1">
                            <img src={img1} alt="" />
                          </div>
                          <div className="group-name-2">
                            <h2>Public</h2> <h3>Course Title</h3>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                        <div className="group-total-members">
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <div className="member-count">
                            <h2>+34</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                        <div className="group-date-added">
                          <h2>August 19, 2023</h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                        <div className="group-name">
                          <div className="group-name-1">
                            <img src={img1} alt="" />
                          </div>
                          <div className="group-name-2">
                            <h2>Public</h2> <h3>Course Title</h3>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                        <div className="group-total-members">
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <div className="member-count">
                            <h2>+34</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                        <div className="group-date-added">
                          <h2>August 19, 2023</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studentcontents;
