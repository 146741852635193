import React, { useEffect, useState } from 'react';
import "./setting.css";
import LOGO from "../assets/logo2.png";
import tempimg from "../assets/Frame 3648.png"
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faL } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Upernav from './Upernav';

import BIN from "../assets/WELCOME BAR.png";
import PROF from "../assets/Frame 3661.png";

function Settings() {
    const [isUlVisible, setIsUlVisible] = useState(false);
    const toggleUlVisibility = () => {
        setIsUlVisible(!isUlVisible);
    };
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };
    const [loading, setLoading] = useState(true);
    const Token = localStorage.getItem("token");
    const[user,setUser]=useState([]);
    useEffect(() => {
        setLoading(false)
    }, [])
    useEffect(() => {
        // Make the API request using fetch
        const token1 = localStorage.getItem("token");
        const id=localStorage.getItem("info1")
        fetch(`https://api.mastertattooinstitute.com/api/v1/user/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token1}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setUser(data)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }, []);
    return (
        <div className="body-background">
            <div className="full-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="fixer">
                                <div className="data">
                                    <img src={LOGO} />
                                </div>
                                <div className="leftnav">
                                    <button onClick={toggleUlVisibility}>
                                        <FontAwesomeIcon
                                            className="bars"
                                            icon={faBars}
                                            style={{ color: "#283C55" }}
                                        />
                                    </button>
                                    <ul
                                        className="leftnav-1 padder"
                                        style={{ display: isUlVisible ? "none" : "block" }}
                                    >
                                        <li>
                                            <Link to="/welcomepage">
                                                <FontAwesomeIcon
                                                    icon={faTableColumns}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Dashboard</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/inbox'}>
                                                <FontAwesomeIcon
                                                    icon={faInbox}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Inbox</h2>
                                            </Link>
                                        </li>
                                        <hr />
                                        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link to="/content">
                                                <FontAwesomeIcon
                                                    icon={faClipboard}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Content</h2>
                                            </Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none" style={{ marginTop: '7px' }}>
                                                <path d="M15 1L8 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </li>
                                        <li>
                                            <Link to="/studentsp">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Explore Student Portfolio</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/studentreg">
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Users</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul
                                        className="leftnav-2 padder"
                                        style={{ display: isUlVisible ? "none" : "block", padding: '0px 10px' }}
                                    >
                                        <li>
                                            <Link to={'/help'}>
                                                <FontAwesomeIcon
                                                    icon={faCircleInfo}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Help</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/settings'} className="isactive">
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Settings</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className="div-style">
                                <div className="container">
                                    <Upernav />
                                </div>
                                <div className="container-fuild">
                                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                                </div>
                                {loading ? (
                                    <div className="kkw">
                                        <span class="loaders"></span>
                                    </div>
                                ) : (
                                    <div>

                                        <div className='container account-setting'>
                                            <br />
                                            <h1>Account Details</h1>
                                        </div>
                                        <div className="wlcom1" style={{ marginTop: '40px' }}>
                                            <img src={BIN} className="bin1"></img>
                                            {user?.profilePicture?.fileName ?(
                                                <img
                                                    className="prof1"
                                                    src={'https://api.mastertattooinstitute.com/' + user.profilePicture.fileName }
                                                    alt="User Profile"
                                                />
                                                ) : ( 
                                            <img
                                                className="prof1"
                                                src={PROF}
                                                alt="User Profile"
                                            />
                                                )}

                                            <div className="e1">
                                                <h6 style={{ fontSize: '20px' }}>{user.firstName+" "+user.lastName}</h6>
                                               
                                            </div>
                                            <br></br>

                                            <div>
                                                <hr></hr>

                                            </div>

                                            <div className="tray1" style={{ padding: '10px 25px', margin: '0' }}>
                                                <div className="details-wrapper1">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <h2 className="details-title1">Personal Information</h2>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Full Name</h2>
                                                                    <h2 className="details-21">{user.firstName+" "+user.lastName}</h2>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Registration Number</h2>
                                                                    <h2 className="details-21">{user.registerationNumber}</h2>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Gender</h2>
                                                                    <h2 className="details-21">{user.gender}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <h2 className="details-title1">Contact Information</h2>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Phone Number</h2>
                                                                    <h2 className="details-21">{user.phone}</h2>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Email Address</h2>
                                                                    <h2 className="details-21">{user.email}</h2>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Favroite Movies</h2>
                                                                    <h2 className="details-21">{user.degree}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <h2 className="details-title1">Hobbies & Interest</h2>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Favroite Hobbies</h2>
                                                                    <h2 className="details-21">{user.certification}</h2>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Favroite Music</h2>
                                                                    <h2 className="details-21">{user.education}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <h2 className="details-title1">Professional Information</h2>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Role</h2>
                                                                    <h2 className="details-21">{user.role}</h2>
                                                                </div>
                                                                <div className="col-lg-8 col-md-6 col-sm-12">
                                                                    <h2 className="details-11">Skills</h2>
                                                                    <div className="details-skills1">

                                                                        <h2 className="details-31">{user.skill}</h2>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <br></br>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                        // onChange={handleFileChange}
                                        />

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Settings