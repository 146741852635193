import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import "./studentregis.css";
import LOGO from "../assets/logo2.png";
import "reactjs-popup/dist/index.css";
import IMG from "../assets/Frame 3669.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Upernav from "./Upernav";
function StudentReg() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const name = localStorage.getItem("info")
  const [isUlVisible, setIsUlVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [file, setFile] = useState();
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [visibleStudents, setVisibleStudents] = useState(20);
  const Token = localStorage.getItem("token");
  const [showAll, setShowAll] = useState(false)
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/user/search?name=${query}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      if (response.data === null || response.data.length === 0) {
        setShowNotFound(true);
        const timer = setTimeout(() => {
          setShowNotFound(false);
        }, 2000);

        return () => clearTimeout(timer);
      } else {
       
        setResults(response.data);
        setShowNotFound(false);
      }
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setLoading(false);
    }
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false);
  const [stautsApplied, setstatusApplied] = useState(false);
  useEffect(() => {
    // Make the API request using fetch
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/user?role=student`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if(stautsApplied){ 
          setUser(data);
        }
        else if(filterApplied){
          const sortedResults = data.sort((a, b) => {
            // Assuming registrationDate is a timestamp field
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setUser(sortedResults);
        }
        else{
          setUser(data)
        }
        console.log(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [currentPage, visibleStudents,filterApplied,stautsApplied]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const removeUsers = async (id) => {
    const token1 = localStorage.getItem("token");
    try {
      const response = await fetch(`https://api.mastertattooinstitute.com/api/v1/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token1}`,
        },
      });

      if (response.ok) {
        console.log('Deleted! successfully');
        setIsDeleteSuccessful(true);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setIsDeleteSuccessful(false);
        window.location.reload();
      } else {
        console.error('Failed to delete Students');
      }
    } catch (error) {
      console.error('Error during delete request:', error);
    }
  }
  const startIndex = (currentPage - 1) * visibleStudents;
  const endIndex = startIndex + visibleStudents;
  

  const handleFilterButtonClick = () => {
    setFilterApplied(!filterApplied);
  };
  const handleStatusButtonClick = () => {
    setstatusApplied(!stautsApplied);
  };
  return (
    <div className="body-background">
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="full-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="fixer">
                  <div className="data">
                    <img src={LOGO} />
                  </div>
                  <div className="leftnav">
                    <button onClick={toggleUlVisibility}>
                      <FontAwesomeIcon
                        className="bars"
                        icon={faBars}
                        style={{ color: "#283C55" }}
                      />
                    </button>
                    <ul
                      className="leftnav-1 padder"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <Link to="/welcomepage">
                          <FontAwesomeIcon
                            icon={faTableColumns}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Dashboard</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                      <hr />
                      <li>
                        <Link to="/content">
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Content</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentsp">
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Explore Student Portfolio</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentreg" className="isactive">
                          <FontAwesomeIcon
                            icon={faUsers}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Users</h2>
                        </Link>
                      </li>
                      <ul className="sub-tabs-list">
                        <li className="sub-tab isactive1">
                          <Link to="/studentreg">Students</Link>
                        </li>
                        <li className="sub-tab">
                          <Link to="/teacherreg">Instructors</Link>
                        </li>
                        <li className="sub-tab">
                          <Link to="/groups">Groups</Link>
                        </li>
                      </ul>
                    </ul>
                    <ul
                      className="leftnav-2 padder"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >

                      <li>
                        <Link to={'/help'}>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Help</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/settings'}>
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Settings</h2>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="div-style">
                  <div className="container">
                    <Upernav />
                  </div>
                  <div>
                    {isDeleteSuccessful && (
                      <div className="popup-del">
                        <p>Student deleted successfully!</p>
                      </div>
                    )}
                  </div>
                  <div className="container-fuild">
                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)', marginBottom: '5px', marginTop: '5px' }}></p>
                  </div>
                  <div className="container back">
                    <div className="content-header">
                      <div className="content-folders">
                        <h2>
                          <em>{user.length}</em> Students In Total
                        </h2>
                      </div>
                      <div className="content-controls">
                        <button onClick={handleStatusButtonClick} className="numo">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ color: "#283C5580" }}
                        />
                        <h2>All status</h2>
                        </button>
                        <button onClick={handleFilterButtonClick}>
                          <FontAwesomeIcon
                            icon={faFilter}
                            style={{ color: "#283C5580" }}
                          />
                          <h2>Filter</h2>
                        </button>
                      </div>
                    </div>
                    <div className="btn_flexer">
                      <div className="search-Bar">
                        <input
                          type="text"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          placeholder="Enter your search name"
                          className="search-b"
                        />
                        <div>
                          <button className="search-btn" onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.7441 17.0112L12.8596 12.1267C14.0334 10.7176 14.6187 8.91014 14.4938 7.08042C14.3689 5.25071 13.5434 3.53959 12.1891 2.30302C10.8347 1.06645 9.05571 0.399634 7.22221 0.441294C5.38872 0.482954 3.64187 1.22988 2.34505 2.52669C1.04824 3.82351 0.301314 5.57036 0.259653 7.40385C0.217993 9.23735 0.884807 11.0163 2.12138 12.3707C3.35795 13.7251 5.06907 14.5506 6.89878 14.6755C8.7285 14.8004 10.5359 14.215 11.9451 13.0413L16.8296 17.9258L17.7441 17.0112ZM1.57451 7.57722C1.57451 6.42592 1.91591 5.30048 2.55554 4.34321C3.19517 3.38594 4.10429 2.63984 5.16795 2.19925C6.23161 1.75867 7.40203 1.6434 8.53121 1.868C9.66039 2.09261 10.6976 2.64701 11.5117 3.4611C12.3258 4.27519 12.8802 5.31241 13.1048 6.44158C13.3294 7.57076 13.2141 8.74118 12.7735 9.80484C12.333 10.8685 11.5869 11.7776 10.6296 12.4173C9.67232 13.0569 8.54688 13.3983 7.39558 13.3983C5.85226 13.3966 4.37264 12.7827 3.28135 11.6914C2.19006 10.6002 1.57622 9.12053 1.57451 7.57722Z" fill="#283C55" fill-opacity="0.5" />
                          </svg> Search</button>
                        </div>
                      </div>
                      <Link to="/addstudentreg">
                        <button className="save-btn d">Add Student</button>
                      </Link>
                    </div>
                    <div className="user-table">
                      <div className="user-table-heading">
                        <div className="row">
                          <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                            <h2>Student Name</h2>
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                            <h2>Contact</h2>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                            <h2>Username</h2>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                            <h2>Date Added</h2>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 no-gutter"></div>
                        </div>
                      </div>
                      <hr className="mt-3" />
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-6 no-gutter">
                          <div className="user-data">
                            {results.length === 0 ? (
                              <div>
                                {showNotFound && (
                                  <div className="not-found-message">Not found</div>
                                )}
                              </div>
                            ) : (
                              results.map((result) => (
                                <div className="row" style={{ marginBottom: '10px' }}>
                                  <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                                    <div className="user-data-1">
                                      <div>
                                        {result?.profilePicture?.fileName ? (
                                          // Render the profile picture if fileName is not null
                                          <img src={'https://api.mastertattooinstitute.com/' + result.profilePicture.fileName} alt="Profile" />
                                        ) : (
                                          // Render another picture or a default image when fileName is null
                                          <img src={IMG} alt="Default" />
                                        )}{" "}
                                      </div>
                                      <div>
                                        <h2>{result.firstName + result.lastName}</h2>
                                        <h3>Active</h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                                    <div className="user-data-2">
                                      <div>
                                        <h5>Contact</h5>
                                        <h2>{result.phone}</h2>
                                        <h2>{result.email}</h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                                    <div className="user-data-2 mt-2">
                                      <h5>Username</h5>
                                      <h2>{result.username}</h2>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                                    <div className="user-data-2 mt-2">
                                      <h5>Date Added</h5>
                                      <h2>{formatDate(result.createdAt)}</h2>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-12 col-sm-12 gx-0">
                                    <div className="user-data-3 mt-2 d-flex gap-2">
                                      <Link to={`/studentprofiles/${result.id}`}>Profile</Link>
                                      <button className="see-more1" onClick={() => removeUsers(result.id)}> Delete</button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                          <div className="user-data">
                            {user.slice(startIndex, endIndex).map((result) => (
                              <div className="row" style={{ marginBottom: '10px' }}>
                                <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                                  <div className="user-data-1">
                                    <div>
                                      {result?.profilePicture?.fileName ? (
                                        // Render the profile picture if fileName is not null
                                        <img src={'https://api.mastertattooinstitute.com/' + result.profilePicture.fileName} alt="Profile" />
                                      ) : (
                                        // Render another picture or a default image when fileName is null
                                        <img src={IMG} alt="Default" />
                                      )}{" "}
                                    </div>
                                    <div>
                                      <h2>{result.firstName + result.lastName}</h2>
                                      <h3>Active</h3>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                                  <div className="user-data-2">
                                    <div>
                                      <h5>Contact</h5>
                                      <h2>{result.phone}</h2>
                                      <h2>{result.email}</h2>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                                  <div className="user-data-2 mt-2">
                                    <h5>Username</h5>
                                    <h2>{result.username}</h2>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 no-gutter">
                                  <div className="user-data-2 mt-2">
                                    <h5>Date Added</h5>
                                    <h2>{formatDate(result.createdAt)}</h2>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 gx-0">
                                  <div className="user-data-3 mt-2 d-flex gap-2">
                                    <Link to={`/studentprofiles/${result.id}`}>Profile</Link>
                                    <button className="see-more1" onClick={() => removeUsers(result.id)}> Delete</button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="pagination">
                              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                              <span>Page {currentPage}</span>
                              <button onClick={() => handlePageChange(currentPage + 1)} disabled={user.length < visibleStudents}>Next</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}
export default StudentReg;
