import React, { useState, useEffect } from "react";
import "./assignper.css";
import pio from "../assets/Frame 3648.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link, useParams } from "react-router-dom";
function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}
export default function Assignmentperview() {
  const { courseId } = useParams();
  const [currentDate, setCurrentDate] = useState(getDate());
  const date = new Date();
  const showTime =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const [components, setComponents] = useState(["Sample Component"]);
  const [items, setItems] = useState([]);
  // State to store the current item input
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);
  // Function to handle adding an item to the list
  const addItem = () => {
    if (input.trim() !== "") {
      setItems([...items, input]);
      setInput("");
    }
  };
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const [weekLength, setWeeksLength] = useState('');
  const [weeksid, setWeeks] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseCategory, setCourseCategory] = useState('');
  const [instructor, setInstructor] = useState('');
  const [tStudent, setTStudent] = useState('');
  useEffect(() => {
    // Make the API request using fetch
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setWeeksLength(data.weeks.length)
        setWeeks(data.weeks);
        setCourseName(data);
        setCourseCategory(data.category);
        setInstructor(data.instructor.firstName);
        setTStudent(data.students.length);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
  const weeks = Array.from({ length: weekLength }, (_, index) => index);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [assignmentCheck, setAssignmentCheck] = useState([])
  const handleWeekClick = (week) => {
    setSelectedWeek(week);
    const token1 = localStorage.getItem("token");
    const requestData = {
      week: weeksid[week].id,
      course: courseId,
    };
    fetch('https://api.mastertattooinstitute.com/api/v1/studentAssignment/course/week/students/fetch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then(data => {
        setAssignmentCheck(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  const renderStars = (score) => {
    const stars = [];
    for (let i = 0; i < score; i++) {
      stars.push(
        <i
          key={i}
          className="fa-solid fa-star fa-2xl"
          style={{ color: "#FF912C" }}
        ></i>
      );
    }
    return stars;
  };
  return (
    <div className="body-background">
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-3 col-md-3 col-sm-12"
              style={{ marginTop: "20px" }}
            >
              <div className="border-inleftnav">
                <Link to="/addnewassignment" style={{ textDecoration: "none" }}>
                  <div className="new-create">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 7 13"
                      fill="none"
                      className="back-btn"
                    >
                      <path
                        d="M6.3572 12.4479L1.24609 6.72396L6.35721 1"
                        stroke="#283C55"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="back-tocourse">Back to Course List</p>
                  </div>
                </Link>
                <div className="container bg-color">
                  <div className="general-info">
                    <p className="gen-info">General Information</p>
                    <p className="current-date">
                      Updated on {currentDate}, {showTime}
                    </p>
                  </div>
                </div>
                <div className="weeks-count">
                  <div
                    className="week-list"
                    style={{
                      background: "transparent",
                      marginLeft: "-40px",
                      marginTop: "-12px",
                    }}
                  >
                    <ul>
                      {weeks.map((week) => (
                        <li
                          key={week}
                          className={week === selectedWeek ? "active-week" : ""}
                          onClick={() => handleWeekClick(week)}
                        >
                          <h2>Week - {week + 1}</h2>
                          <p>Updated on August 8, 2023 2:30PM</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-9 col-md-9 col-sm-12 "
              style={{ marginTop: "20px" }}
            >
              <div className="container course-nav-back">
                <div className="course-nav">
                  <div className="b-nav">
                    <p className="course-title">{courseName.title}</p>
                  </div>
                  <div className="nav-date">
                    <p className="current-date">
                      <i class="fa-regular fa-bookmark"></i>&nbsp;Catagory-
                      <span className="tk-names">{courseName.category}</span>
                    </p>
                    <p className="current-date d">
                      <i class="fa-regular fa-user"></i>&nbsp;Instructor-
                      <span className="tk-names">{instructor}</span>
                    </p>
                    <p className="current-date d">
                      <i class="fa-regular fa-user"></i>&nbsp;Total Members-
                      <span className="tk-names">{tStudent}</span>
                    </p>
                  </div>
                </div>
                <hr></hr>
                <div class="container text-center weew">
                  <div class="row">
                    <div class="col-3">
                      <p className="week-table-heading">Name</p>
                    </div>
                    <div class="col-3">
                      <p className="week-table-heading">Assign Date</p>
                    </div>
                    <div class="col-3">
                      <p className="week-table-heading">Status</p>
                    </div>
                    <div class="col-3">
                      <p className="week-table-heading">Result</p>
                    </div>
                  </div>
                </div>
                <p className="b-bottom"></p>
                <div class="container qk text-center">
                  {assignmentCheck.map((item, index) => (
                    <div class="row week-2">
                      <div class="col w">
                        <p className="week-table-heading1">
                          <img src={pio} style={{ width: "20%" }} /> {item.firstName}
                          {/* <span
                        style={{
                          color: "orange",
                          display: "block",
                          marginLeft: "60px",
                          marginTop:'-20px'
                        }}
                      >
                        Active
                      </span> */}
                        </p>
                      </div>
                      <div class="col w">
                        <p className="week-table-heading1">{formatDate(item.assignment.createdAt)}</p>
                      </div>
                      <div class="col w">
                        <p className="com">
                          Completed<span className="compl">100%</span>
                        </p>
                        <div class="progress">
                          <div
                            class="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col w">
                        <Popup
                          trigger={<button className="pnewcourse1">Preview</button>}
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="popup-style">
                              <div>
                                <div className=" croos">
                                  <p className="perview">Preview Assignment</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="31"
                                    height="30"
                                    viewBox="0 0 31 30"
                                    fill="none"
                                    onClick={() => close()}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <path
                                      d="M7.61895 4.5177L15.2993 12.1981L22.9399 4.55749C23.1087 4.37785 23.312 4.23415 23.5376 4.135C23.7633 4.03584 24.0067 3.98328 24.2531 3.98047C24.7808 3.98047 25.2869 4.1901 25.6601 4.56325C26.0332 4.93639 26.2428 5.44249 26.2428 5.9702C26.2475 6.21415 26.2022 6.45647 26.1098 6.68229C26.0175 6.90811 25.8799 7.11268 25.7056 7.28342L17.9656 14.924L25.7056 22.6641C26.0336 22.9849 26.2259 23.4193 26.2428 23.8778C26.2428 24.4055 26.0332 24.9116 25.6601 25.2847C25.2869 25.6579 24.7808 25.8675 24.2531 25.8675C23.9995 25.878 23.7465 25.8357 23.5102 25.7432C23.2738 25.6508 23.0593 25.5101 22.8802 25.3303L15.2993 17.6499L7.63885 25.3104C7.47073 25.484 7.26988 25.6227 7.0479 25.7183C6.82593 25.8139 6.58721 25.8646 6.34552 25.8675C5.81782 25.8675 5.31172 25.6579 4.93857 25.2847C4.56542 24.9116 4.35579 24.4055 4.35579 23.8778C4.35115 23.6338 4.39641 23.3915 4.4888 23.1657C4.58118 22.9399 4.71873 22.7353 4.89302 22.5646L12.6331 14.924L4.89302 7.18394C4.56508 6.86311 4.37278 6.42866 4.35579 5.9702C4.35579 5.44249 4.56542 4.93639 4.93857 4.56325C5.31172 4.1901 5.81782 3.98047 6.34552 3.98047C6.82306 3.98644 7.2807 4.17944 7.61895 4.5177Z"
                                      fill="#283C55"
                                      fill-opacity="0.4"
                                    />
                                  </svg>
                                </div>
                                <div>
                                  <br></br>
                                  {item?.assignment?.score === 0 ? (
                                    <h5>"The assignment cannot be checked at the moment"</h5>
                                  ) : (
                                    renderStars(item.assignment.score)
                                  )}
                                  <br></br>
                                  <br></br>
                                  {item?.assignment?.attachment?.fileName ?
                                    (<img src={'https://api.mastertattooinstitute.com/' + item.assignment.attachment.fileName} style={{ width: "100%" }} />) : (<p>wrong file uploaded</p>)
                                  }
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                    </div>
                  ))}
                </div>
                <p className="b-bottom"></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
