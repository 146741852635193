import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLeaf, faUser } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import img1 from "../assets/Frame 3648.png";
import { Link } from "react-router-dom";
import "./studentsprofils.css";
import pio from "../assets/Frame 3648.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LOGO from "../assets/logo2.png";
import Upernav from "./Upernav";
function Groups() {
  const [activeTab, setActiveTab] = useState("user");
  const showUserContent = () => {
    setActiveTab("user");
  };
  const showContent = () => {
    setActiveTab("content");
  };
  const showGroups = () => {
    setActiveTab("groups");
  };
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className="body-background">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="fixer">
              <div className="data">
                <img src={LOGO} />
              </div>
              <div className="leftnav">
                <button onClick={toggleUlVisibility}>
                  <FontAwesomeIcon
                    className="bars"
                    icon={faBars}
                    style={{ color: "#283C55" }}
                  />
                </button>
                <ul
                  className="leftnav-1 padder"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <Link to="/welcomepage">
                      <FontAwesomeIcon
                        icon={faTableColumns}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Dashboard</h2>
                    </Link>
                  </li>
                  <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                  <hr />
                  <li>
                    <Link to="/content">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Content</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentsp">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Explore Student Portfolio</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentreg" className="isactive">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Users</h2>
                    </Link>
                  </li>
                  <ul className="sub-tabs-list">
                    <li className="sub-tab">
                      <Link to="/studentreg">Students</Link>
                    </li>
                    <li className="sub-tab">
                      <Link to="/teacherreg">Instructors</Link>
                    </li>
                    <li className="sub-tab isactive1">
                      <Link to="/groups">Groups</Link>
                    </li>
                  </ul>
                </ul>
                <ul
                  className="leftnav-2 padder"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                <li>
                    <Link to={'/help'}>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                        />
                        <h2>Help</h2>
                    </Link>
                </li>
                <li>
                    <Link to={'/settings'}>
                        <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                        />
                        <h2>Settings</h2>
                    </Link>
                </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="div-style">
              <div className="container">
                <Upernav />
              </div>
              <div className="container-fuild">
                <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)', marginBottom: '5px', marginTop: '5px' }}></p>
              </div>
              <div className="white-container">
                <div className="content-header">
                  <div className="content-folders">
                    <h2>
                      <em>15</em> Groups
                    </h2>
                  </div>
                  <div className="content-controls">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#283C5580" }}
                    />
                    <h2>All status</h2>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faFilter}
                        style={{ color: "#283C5580" }}
                      />
                      <h2>Filter</h2>
                    </a>
                    <a href=""></a>
                  </div>
                </div>
                <div className="profile-groups">
                  <div className="groups-table">
                    <div className="groups-table-heading">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                          <h2>Group Name</h2>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                          <h2>Total Members</h2>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 no-gutter">
                          <h2>Date Added</h2>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2" />
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                        <div className="group-name">
                          <div className="group-name-1">
                            <img src={img1} alt="" />
                          </div>
                          <div className="group-name-2">
                            <h2>Public</h2> <h3>Course Title</h3>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                        <div className="group-total-members">
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <img src={img1} alt="" />
                          <div className="member-count">
                            <h2>+34</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 no-gutter">
                        <div className="group-date-added">
                          <h2>August 19, 2023</h2>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                        <div className="group-date-added">
                          <div className="ghj">
                            <Popup
                              trigger={
                                <button className="assign-student">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.27626 5.71448H5.71448V9.27626C5.71448 9.66806 5.39392 9.98862 5.00212 9.98862C4.61032 9.98862 4.28976 9.66806 4.28976 9.27626V5.71448H0.727982C0.336185 5.71448 0.015625 5.39392 0.015625 5.00212C0.015625 4.61032 0.336185 4.28976 0.727982 4.28976H4.28976V0.727982C4.28976 0.336185 4.61032 0.015625 5.00212 0.015625C5.39392 0.015625 5.71448 0.336185 5.71448 0.727982V4.28976H9.27626C9.66806 4.28976 9.98862 4.61032 9.98862 5.00212C9.98862 5.39392 9.66806 5.71448 9.27626 5.71448Z"
                                      fill="white"
                                    />
                                  </svg>{" "}
                                  Assign Group
                                </button>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="popup-styl">
                                  <div>
                                    <div>
                                      <div className="course-title-learn">
                                        <p className="learning">
                                          Course Title - Learning Group
                                        </p>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="31"
                                          height="30"
                                          viewBox="0 0 31 30"
                                          fill="none"
                                          onClick={() => close()}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <path
                                            d="M7.61895 4.5177L15.2993 12.1981L22.9399 4.55749C23.1087 4.37785 23.312 4.23415 23.5376 4.135C23.7633 4.03584 24.0067 3.98328 24.2531 3.98047C24.7808 3.98047 25.2869 4.1901 25.6601 4.56325C26.0332 4.93639 26.2428 5.44249 26.2428 5.9702C26.2475 6.21415 26.2022 6.45647 26.1098 6.68229C26.0175 6.90811 25.8799 7.11268 25.7056 7.28342L17.9656 14.924L25.7056 22.6641C26.0336 22.9849 26.2259 23.4193 26.2428 23.8778C26.2428 24.4055 26.0332 24.9116 25.6601 25.2847C25.2869 25.6579 24.7808 25.8675 24.2531 25.8675C23.9995 25.878 23.7465 25.8357 23.5102 25.7432C23.2738 25.6508 23.0593 25.5101 22.8802 25.3303L15.2993 17.6499L7.63885 25.3104C7.47073 25.484 7.26988 25.6227 7.0479 25.7183C6.82593 25.8139 6.58721 25.8646 6.34552 25.8675C5.81782 25.8675 5.31172 25.6579 4.93857 25.2847C4.56542 24.9116 4.35579 24.4055 4.35579 23.8778C4.35115 23.6338 4.39641 23.3915 4.4888 23.1657C4.58118 22.9399 4.71873 22.7353 4.89302 22.5646L12.6331 14.924L4.89302 7.18394C4.56508 6.86311 4.37278 6.42866 4.35579 5.9702C4.35579 5.44249 4.56542 4.93639 4.93857 4.56325C5.31172 4.1901 5.81782 3.98047 6.34552 3.98047C6.82306 3.98644 7.2807 4.17944 7.61895 4.5177Z"
                                            fill="#283C55"
                                            fill-opacity="0.4"
                                          />
                                        </svg>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div class="container con-hide">
                                        <div class="row">
                                          <div class="col-3">
                                            <p className="week-table-heading">
                                              Name
                                            </p>
                                          </div>
                                          <div class="col-3">
                                            <p className="week-table-heading">
                                              Contact
                                            </p>
                                          </div>
                                          <div class="col-3">
                                            <p className="week-table-heading">
                                              Department
                                            </p>
                                          </div>
                                          <div class="col-3">
                                            <p className="week-table-heading">
                                              Group
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="srcoll">
                                        <p className="b-bottom"></p>
                                        <div class="container">
                                          <div class="row week-2">
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                <img
                                                  src={pio}
                                                  style={{ width: "20%" }}
                                                />{" "}
                                                Andrew
                                                <span
                                                  style={{
                                                    color: "orange",
                                                    display: "block",
                                                    marginLeft: "30px",
                                                  }}
                                                >
                                                  Active
                                                </span>
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                +0 00 000 000
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                Tattoo Design
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <div className="checkboc">
                                                <p className="week-table-heading1">
                                                  Tattoo Course
                                                </p>
                                                <input
                                                  type="checkbox"
                                                  id="vehicle1"
                                                  name="vehicle1"
                                                  value="Bike"
                                                  className="check-box"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="b-bottom"></p>
                                        <div class="container qk">
                                          <div class="row week-2">
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                <img
                                                  src={pio}
                                                  style={{ width: "20%" }}
                                                />{" "}
                                                Andrew
                                                <span
                                                  style={{
                                                    color: "orange",
                                                    display: "block",
                                                    marginLeft: "30px",
                                                  }}
                                                >
                                                  Active
                                                </span>
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                +0 00 000 000
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                Tattoo Design
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <div className="checkboc">
                                                <p className="week-table-heading1">
                                                  Tattoo Course
                                                </p>
                                                <input
                                                  type="checkbox"
                                                  id="vehicle1"
                                                  name="vehicle1"
                                                  value="Bike"
                                                  className="check-box"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="b-bottom"></p>
                                        <div class="container qk">
                                          <div class="row week-2">
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                <img
                                                  src={pio}
                                                  style={{ width: "20%" }}
                                                />{" "}
                                                Andrew
                                                <span
                                                  style={{
                                                    color: "orange",
                                                    display: "block",
                                                    marginLeft: "30px",
                                                  }}
                                                >
                                                  Active
                                                </span>
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                +0 00 000 000
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                Tattoo Design
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <div className="checkboc">
                                                <p className="week-table-heading1">
                                                  Tattoo Course
                                                </p>
                                                <input
                                                  type="checkbox"
                                                  id="vehicle1"
                                                  name="vehicle1"
                                                  value="Bike"
                                                  className="check-box"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="b-bottom"></p>
                                        <div class="container qk">
                                          <div class="row week-2">
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                <img
                                                  src={pio}
                                                  style={{ width: "20%" }}
                                                />{" "}
                                                Andrew
                                                <span
                                                  style={{
                                                    color: "orange",
                                                    display: "block",
                                                    marginLeft: "30px",
                                                  }}
                                                >
                                                  Active
                                                </span>
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                +0 00 000 000
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                Tattoo Design
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <div className="checkboc">
                                                <p className="week-table-heading1">
                                                  Tattoo Course
                                                </p>
                                                <input
                                                  type="checkbox"
                                                  id="vehicle1"
                                                  name="vehicle1"
                                                  value="Bike"
                                                  className="check-box"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="b-bottom"></p>
                                        <div class="container qk">
                                          <div class="row week-2">
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                <img
                                                  src={pio}
                                                  style={{ width: "20%" }}
                                                />{" "}
                                                Andrew
                                                <span
                                                  style={{
                                                    color: "orange",
                                                    display: "block",
                                                    marginLeft: "30px",
                                                  }}
                                                >
                                                  Active
                                                </span>
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                +0 00 000 000
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <p className="week-table-heading1">
                                                Tattoo Design
                                              </p>
                                            </div>
                                            <div class="col w">
                                              <div className="checkboc">
                                                <p className="week-table-heading1">
                                                  Tattoo Course
                                                </p>
                                                <input
                                                  type="checkbox"
                                                  id="vehicle1"
                                                  name="vehicle1"
                                                  value="Bike"
                                                  className="check-box"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="pop-brn">
                                        <button className="cancel-btn">
                                          Cancel
                                        </button>
                                        <button className="assi-btn">Assign</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Groups;
