import React, { useState, useEffect } from "react";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LOG from "../assets/Frame 3720.png";
import "./stud.css";
import pio from "../assets/Frame 3648.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import Group from "../assets/Group 26.png";
import axios from "axios";
import Upernav from "./Upernav";
export default function Students() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const name = localStorage.getItem("info")
  const { courseId } = useParams();
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const Token = localStorage.getItem("token");
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/user/search?name=${query}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setResults(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setLoading(false);
    }
  };
  const [isChecked, setIsChecked] = useState(false);
  // const [studentId, setStudentId] = useState([]);
  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  //   // If you have a student ID associated with the checkbox, set it here
  //   const id = event.target.value; 
  //   // Replace with the actual way you get the student ID
  //   setStudentId(id);
  // };
  // // const [responseData, setResponseData] = useState([]);
  // // setResponseData(studentId);
  // console.log(studentId)
  const [selectedIds, setSelectedIds] = useState([]);
  const handleCheckboxChange = (event) => {
    const id = event.target.value;
    // Toggle the selected status of the ID
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  console.log(selectedIds)
  const handleButtonClick1 = async () => {
    // console.log(responseData)
    try {
      // Replace 'your-api-endpoint' with the actual API endpoint
      const response = await axios.post(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}/assignStudent`, {
        // Replace with the data you want to send in the request body
        'students': selectedIds,
      }, {
        // Replace with the data you want to send in the request body
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setSelectedIds('');
      // setResponseData(response.data);
      console.log(response.data);
      setValidationMessage("Succesfully assigned");
      setTimeout(() => {
        setValidationMessage('');
      }, 3000);
      window.location.reload();
      setLoading(false);
    }
    catch (error) {
      console.error('Error posting data:', error);
      setValidationMessage("cannot assigned Try again");
      setTimeout(() => {
        setValidationMessage('');
      }, 3000);
      setLoading(false);
    }
  };
  const [selectedIds1, setSelectedIds1] = useState('');
  const handleCheckboxChange12 = (event) => {
    const id = event.target.value;
    setSelectedIds1(id)
  };
  const handleButtonClick12 = async () => {
    // console.log(responseData)
    try {
      // Replace 'your-api-endpoint' with the actual API endpoint
      const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}/instructor/${selectedIds1}/assign`, {
        // Replace with the data you want to send in the request body
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      // setResponseData(response.data);
      console.log(response.data);
      setSelectedIds1('');
      setValidationMessage("Assigned Successfully");
      setTimeout(() => {
        setValidationMessage('');
      }, 3000);
      // window.location.reload();
      setLoading(false);
    }
    catch (error) {
      console.error('Error posting data:', error);
      setValidationMessage("cannot assigned Try again");
      setTimeout(() => {
        setValidationMessage('');
      }, 3000);
      setLoading(false);
    }
  };
  const [studentName, setassignName] = useState([]);
  const [courseDate1, setData1] = useState('');
  const [courseTitle, settitle] = useState('');
  const [cover, setCover] = useState(null);
  const [studentProfile,setProfile]=useState([]);
  const[pic,setpic]=useState([]);
  useEffect(() => {
    // Function to make the API request
    const Token = localStorage.getItem("token")
    const fetchData1 = async () => {
      try {
        // Replace 'your-api-endpoint' with the actual API endpoint
        const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`,
          {
            // Replace with the data you want to send in the request body
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          });
        // setResponseData(response.data);
        setassignName(response.data.students);
        const students = response.data.students;

        // Object to store attachment responses with student IDs as keys
        const attachmentResponses = {};
  
        // Fetch attachments for each student
        for (const student of students) {
          const profilePictureId = student.profilePicture;
  
          const attachmentResponse = await axios.get(`https://api.mastertattooinstitute.com/api/v1/attachment/${profilePictureId}`, {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          });
  
          // Store the attachment response with the student ID
          attachmentResponses[student.id] = attachmentResponse.data.fileName;
        }
  
        // Set the profile pictures with student IDs as keys
        setpic(attachmentResponses);
        setData1(response.data.createdAt);
        settitle(response.data.title)
        setCover(response.data.cover.fileName)
        setLoading(false);
      }
      catch (error) {
        console.error('Error posting data:', error);
      }
    };
    // Call the fetchData function
    fetchData1();
  }, []);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  return (
    <div className="body-background">
      <div>
        {validationMessage && (
          <div className={`successm`}>
            <p>{validationMessage}</p>
          </div>
        )}
      </div>
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="full-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="fixer">
                  <div className="data">
                    <img src={LOGO} />
                  </div>
                  <div className="leftnav">
                    <button onClick={toggleUlVisibility}>
                      <FontAwesomeIcon
                        className="bars"
                        icon={faBars}
                        style={{ color: "#283C55" }}
                      />
                    </button>
                    <ul
                      className="leftnav-1"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <Link to="/welcomepage">
                          <FontAwesomeIcon
                            icon={faTableColumns}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Dashboard</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                      <hr />
                      <li>
                        <Link to="/content" className="isactive">
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Content</h2>
                        </Link>
                        <ul className="sub-tabs-list">
                          <li className="sub-tab isactive1">
                            <Link to="/content">Courses</Link>
                          </li>
                          <li className="sub-tab">
                            <Link to="/quiz">Quiz</Link>
                          </li>
                          <li className="sub-tab">
                            <Link to="/addnewassignment">Assignments</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/studentsp">
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Explore Student Portfolio</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentreg">
                          <FontAwesomeIcon
                            icon={faUsers}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Users</h2>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className="leftnav-2"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >

                      <li>
                        <Link to={'/help'}>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Help</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/settings'}>
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Settings</h2>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="div-style">
                  <div className="container">
                    <Upernav />
                  </div>
                  <div className="container-fuild">
                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)', marginBottom: '5px', marginTop: '5px' }}></p>
                  </div>
                  <div className="container mt-3">
                    <Link to={'/content'} className="back-course">
                      <img src={Group} />
                      &nbsp; Back to Courses
                    </Link>
                  </div>
                  <div className="container back">
                    {cover ? (
                      // Render the profile picture if fileName is not null
                      <img src={'https://api.mastertattooinstitute.com/' + cover} alt="Profile" className="banner-img" />
                    ) : (
                      // Render another picture or a default image when fileName is null
                      <img src={LOG} alt="" className="banner-img" />
                    )}{" "}
                    <p className="course-t">Course Title - {courseTitle}</p>
                    <div className="perview">
                      <div className="public">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="21"
                          viewBox="0 0 22 21"
                          fill="none"
                        >
                          <path
                            d="M3.97521 8.14271H18.2654M3.97521 13.2464H18.2654M3.46484 10.6945C3.46484 11.6999 3.66286 12.6954 4.04758 13.6242C4.43231 14.553 4.9962 15.3969 5.70708 16.1078C6.41796 16.8187 7.26189 17.3826 8.1907 17.7673C9.1195 18.152 10.115 18.35 11.1203 18.35C12.1257 18.35 13.1211 18.152 14.0499 17.7673C14.9787 17.3826 15.8227 16.8187 16.5336 16.1078C17.2444 15.3969 17.8083 14.553 18.1931 13.6242C18.5778 12.6954 18.7758 11.6999 18.7758 10.6945C18.7758 8.66418 17.9692 6.71698 16.5336 5.2813C15.0979 3.84562 13.1507 3.03906 11.1203 3.03906C9.08996 3.03906 7.14276 3.84562 5.70708 5.2813C4.2714 6.71698 3.46484 8.66418 3.46484 10.6945Z"
                            stroke="#283C55"
                            stroke-width="1.70122"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.6966 3.03906C9.26361 5.33537 8.50391 7.98779 8.50391 10.6945C8.50391 13.4013 9.26361 16.0537 10.6966 18.35M11.5472 3.03906C12.9802 5.33537 13.7399 7.98779 13.7399 10.6945C13.7399 13.4013 12.9802 16.0537 11.5472 18.35"
                            stroke="#283C55"
                            stroke-width="1.70122"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p className="published">Published</p>
                      </div>
                      <div className="public">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                        >
                          <path
                            d="M6.29688 10.5547H8.08995V12.3478H6.29688V10.5547ZM6.29688 14.1408H8.08995V15.9339H6.29688V14.1408ZM9.88302 10.5547H11.6761V12.3478H9.88302V10.5547ZM9.88302 14.1408H11.6761V15.9339H9.88302V14.1408ZM13.4692 10.5547H15.2622V12.3478H13.4692V10.5547ZM13.4692 14.1408H15.2622V15.9339H13.4692V14.1408Z"
                            fill="#283C55"
                            fill-opacity="0.6"
                          />
                          <path
                            d="M4.50011 20.4151H17.0516C18.0405 20.4151 18.8447 19.6109 18.8447 18.622V6.07052C18.8447 5.08164 18.0405 4.27745 17.0516 4.27745H15.2586V2.48438H13.4655V4.27745H8.08625V2.48438H6.29318V4.27745H4.50011C3.51123 4.27745 2.70703 5.08164 2.70703 6.07052V18.622C2.70703 19.6109 3.51123 20.4151 4.50011 20.4151ZM17.0516 7.8636L17.0525 18.622H4.50011V7.8636H17.0516Z"
                            fill="#283C55"
                            fill-opacity="0.6"
                          />
                        </svg>
                        <p className="published">Created on {formatDate(courseDate1)}</p>
                      </div>
                      <div className="public">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_719_6782)">
                            <path
                              d="M8.7895 7.28711C8.2159 7.28711 7.66579 7.51497 7.2602 7.92057C6.8546 8.32617 6.62674 8.87627 6.62674 9.44987C6.62674 10.0235 6.8546 10.5736 7.2602 10.9792C7.66579 11.3848 8.2159 11.6126 8.7895 11.6126C9.3631 11.6126 9.91321 11.3848 10.3188 10.9792C10.7244 10.5736 10.9523 10.0235 10.9523 9.44987C10.9523 8.87627 10.7244 8.32617 10.3188 7.92057C9.91321 7.51497 9.3631 7.28711 8.7895 7.28711ZM8.7895 13.0545C7.8335 13.0545 6.91666 12.6747 6.24066 11.9987C5.56467 11.3227 5.1849 10.4059 5.1849 9.44987C5.1849 8.49387 5.56467 7.57703 6.24066 6.90103C6.91666 6.22504 7.8335 5.84527 8.7895 5.84527C9.7455 5.84527 10.6623 6.22504 11.3383 6.90103C12.0143 7.57703 12.3941 8.49387 12.3941 9.44987C12.3941 10.4059 12.0143 11.3227 11.3383 11.9987C10.6623 12.6747 9.7455 13.0545 8.7895 13.0545ZM8.7895 4.04297C5.1849 4.04297 2.10657 6.28503 0.859375 9.44987C2.10657 12.6147 5.1849 14.8568 8.7895 14.8568C12.3941 14.8568 15.4724 12.6147 16.7196 9.44987C15.4724 6.28503 12.3941 4.04297 8.7895 4.04297Z"
                              fill="#283C55"
                              fill-opacity="0.6"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_719_6782">
                              <rect
                                width="17.3021"
                                height="17.3021"
                                fill="white"
                                transform="translate(0.136719 0.796875)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className="published">Public</p>
                      </div>
                    </div>
                    <div className="nav-perview">
                      <Link to={`/coursepreview/${courseId}`} className="con">
                        Content
                      </Link>
                      <Link to={`/statistic/${courseId}`} className="con">
                        Statistics
                      </Link>
                      <Link to={`/students/${courseId}`} className="con con-is">
                        Students
                      </Link>
                    </div>
                    <hr></hr>
                    <div className="divee">
                      <div className="ghj">
                        <Popup
                          trigger={
                            <button className="assign-student">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <path
                                  d="M9.27626 5.71448H5.71448V9.27626C5.71448 9.66806 5.39392 9.98862 5.00212 9.98862C4.61032 9.98862 4.28976 9.66806 4.28976 9.27626V5.71448H0.727982C0.336185 5.71448 0.015625 5.39392 0.015625 5.00212C0.015625 4.61032 0.336185 4.28976 0.727982 4.28976H4.28976V0.727982C4.28976 0.336185 4.61032 0.015625 5.00212 0.015625C5.39392 0.015625 5.71448 0.336185 5.71448 0.727982V4.28976H9.27626C9.66806 4.28976 9.98862 4.61032 9.98862 5.00212C9.98862 5.39392 9.66806 5.71448 9.27626 5.71448Z"
                                  fill="white"
                                />
                              </svg>{" "}
                              Assign Student
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="popup-styl">
                              <div>
                                <div>
                                  <div className="course-title-learn">
                                    <p className="learning">
                                      Course Title -  {courseTitle}
                                    </p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="31"
                                      height="30"
                                      viewBox="0 0 31 30"
                                      fill="none"
                                      onClick={() => close()}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <path
                                        d="M7.61895 4.5177L15.2993 12.1981L22.9399 4.55749C23.1087 4.37785 23.312 4.23415 23.5376 4.135C23.7633 4.03584 24.0067 3.98328 24.2531 3.98047C24.7808 3.98047 25.2869 4.1901 25.6601 4.56325C26.0332 4.93639 26.2428 5.44249 26.2428 5.9702C26.2475 6.21415 26.2022 6.45647 26.1098 6.68229C26.0175 6.90811 25.8799 7.11268 25.7056 7.28342L17.9656 14.924L25.7056 22.6641C26.0336 22.9849 26.2259 23.4193 26.2428 23.8778C26.2428 24.4055 26.0332 24.9116 25.6601 25.2847C25.2869 25.6579 24.7808 25.8675 24.2531 25.8675C23.9995 25.878 23.7465 25.8357 23.5102 25.7432C23.2738 25.6508 23.0593 25.5101 22.8802 25.3303L15.2993 17.6499L7.63885 25.3104C7.47073 25.484 7.26988 25.6227 7.0479 25.7183C6.82593 25.8139 6.58721 25.8646 6.34552 25.8675C5.81782 25.8675 5.31172 25.6579 4.93857 25.2847C4.56542 24.9116 4.35579 24.4055 4.35579 23.8778C4.35115 23.6338 4.39641 23.3915 4.4888 23.1657C4.58118 22.9399 4.71873 22.7353 4.89302 22.5646L12.6331 14.924L4.89302 7.18394C4.56508 6.86311 4.37278 6.42866 4.35579 5.9702C4.35579 5.44249 4.56542 4.93639 4.93857 4.56325C5.31172 4.1901 5.81782 3.98047 6.34552 3.98047C6.82306 3.98644 7.2807 4.17944 7.61895 4.5177Z"
                                        fill="#283C55"
                                        fill-opacity="0.4"
                                      />
                                    </svg>
                                  </div>
                                  <div className="search-Bar">
                                    <input
                                      type="text"
                                      value={query}
                                      onChange={(e) => setQuery(e.target.value)}
                                      placeholder="Enter your search name"
                                      className="search-b"
                                    />
                                    <button className="search-btn" onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                      <path d="M17.7441 17.0112L12.8596 12.1267C14.0334 10.7176 14.6187 8.91014 14.4938 7.08042C14.3689 5.25071 13.5434 3.53959 12.1891 2.30302C10.8347 1.06645 9.05571 0.399634 7.22221 0.441294C5.38872 0.482954 3.64187 1.22988 2.34505 2.52669C1.04824 3.82351 0.301314 5.57036 0.259653 7.40385C0.217993 9.23735 0.884807 11.0163 2.12138 12.3707C3.35795 13.7251 5.06907 14.5506 6.89878 14.6755C8.7285 14.8004 10.5359 14.215 11.9451 13.0413L16.8296 17.9258L17.7441 17.0112ZM1.57451 7.57722C1.57451 6.42592 1.91591 5.30048 2.55554 4.34321C3.19517 3.38594 4.10429 2.63984 5.16795 2.19925C6.23161 1.75867 7.40203 1.6434 8.53121 1.868C9.66039 2.09261 10.6976 2.64701 11.5117 3.4611C12.3258 4.27519 12.8802 5.31241 13.1048 6.44158C13.3294 7.57076 13.2141 8.74118 12.7735 9.80484C12.333 10.8685 11.5869 11.7776 10.6296 12.4173C9.67232 13.0569 8.54688 13.3983 7.39558 13.3983C5.85226 13.3966 4.37264 12.7827 3.28135 11.6914C2.19006 10.6002 1.57622 9.12053 1.57451 7.57722Z" fill="#283C55" fill-opacity="0.5" />
                                    </svg> Search</button>
                                  </div>
                                  <br></br>
                                  <br></br>
                                  <div class="container con-hide">
                                    <div class="row">
                                      <div class="col-3">
                                        <p className="week-table-heading">Name</p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">Contact</p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">
                                          Username
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">Group</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="srcoll">
                                    <p className="b-bottom"></p>
                                    {results.map((result) => (
                                      <div class="container">
                                        <div class="row week-2">
                                          <div class="col-3 w">
                                            <div className="week-table-heading1">
                                              {result?.profilePicture?.fileName ? (
                                                // Render the profile picture if fileName is not null
                                                <img src={'https://api.mastertattooinstitute.com/' + result.profilePicture.fileName} style={{ width: '15%' }} alt="Profile" />
                                              ) : (
                                                // Render another picture or a default image when fileName is null
                                                <img src={pio} alt="Default" style={{ width: '15%' }} />
                                              )}{" "}
                                              {result.firstName + result.lastName}
                                              <span
                                                style={{
                                                  color: "orange",
                                                  display: "block",
                                                  marginLeft: "30px",
                                                }}
                                              >
                                                Active
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-3 w">
                                            <p className="week-table-heading1">
                                              {result.phone}
                                            </p>
                                          </div>
                                          <div class="col-3 w">
                                            <p className="week-table-heading1">
                                              {result.username}
                                            </p>
                                          </div>
                                          <div class="col-2">
                                            <div className="checkboc">
                                              <p className="week-table-heading1">
                                                {result.username}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-1">
                                            <div className="checkboc d-flex align-items-center mt-1">
                                              <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                className="check-box"
                                                onChange={handleCheckboxChange}
                                                value={result.id}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <p className="b-bottom"></p>
                                  </div>
                                  <br></br>
                                  <div className="pop-brn">
                                    <button className="cancel-btn" onClick={() => close()}>Cancel</button>
                                    <button className="assi-btn" onClick={handleButtonClick1} >Assign</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                      <div className="ghj">
                        <Popup
                          trigger={
                            <button className="assign-student">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <path
                                  d="M9.27626 5.71448H5.71448V9.27626C5.71448 9.66806 5.39392 9.98862 5.00212 9.98862C4.61032 9.98862 4.28976 9.66806 4.28976 9.27626V5.71448H0.727982C0.336185 5.71448 0.015625 5.39392 0.015625 5.00212C0.015625 4.61032 0.336185 4.28976 0.727982 4.28976H4.28976V0.727982C4.28976 0.336185 4.61032 0.015625 5.00212 0.015625C5.39392 0.015625 5.71448 0.336185 5.71448 0.727982V4.28976H9.27626C9.66806 4.28976 9.98862 4.61032 9.98862 5.00212C9.98862 5.39392 9.66806 5.71448 9.27626 5.71448Z"
                                  fill="white"
                                />
                              </svg>{" "}
                              Assign Instructor
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="popup-styl">
                              <div>
                                <div>
                                  <div className="course-title-learn">
                                    <p className="learning">
                                      Course Title -  {courseTitle}
                                    </p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="31"
                                      height="30"
                                      viewBox="0 0 31 30"
                                      fill="none"
                                      onClick={() => close()}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <path
                                        d="M7.61895 4.5177L15.2993 12.1981L22.9399 4.55749C23.1087 4.37785 23.312 4.23415 23.5376 4.135C23.7633 4.03584 24.0067 3.98328 24.2531 3.98047C24.7808 3.98047 25.2869 4.1901 25.6601 4.56325C26.0332 4.93639 26.2428 5.44249 26.2428 5.9702C26.2475 6.21415 26.2022 6.45647 26.1098 6.68229C26.0175 6.90811 25.8799 7.11268 25.7056 7.28342L17.9656 14.924L25.7056 22.6641C26.0336 22.9849 26.2259 23.4193 26.2428 23.8778C26.2428 24.4055 26.0332 24.9116 25.6601 25.2847C25.2869 25.6579 24.7808 25.8675 24.2531 25.8675C23.9995 25.878 23.7465 25.8357 23.5102 25.7432C23.2738 25.6508 23.0593 25.5101 22.8802 25.3303L15.2993 17.6499L7.63885 25.3104C7.47073 25.484 7.26988 25.6227 7.0479 25.7183C6.82593 25.8139 6.58721 25.8646 6.34552 25.8675C5.81782 25.8675 5.31172 25.6579 4.93857 25.2847C4.56542 24.9116 4.35579 24.4055 4.35579 23.8778C4.35115 23.6338 4.39641 23.3915 4.4888 23.1657C4.58118 22.9399 4.71873 22.7353 4.89302 22.5646L12.6331 14.924L4.89302 7.18394C4.56508 6.86311 4.37278 6.42866 4.35579 5.9702C4.35579 5.44249 4.56542 4.93639 4.93857 4.56325C5.31172 4.1901 5.81782 3.98047 6.34552 3.98047C6.82306 3.98644 7.2807 4.17944 7.61895 4.5177Z"
                                        fill="#283C55"
                                        fill-opacity="0.4"
                                      />
                                    </svg>
                                  </div>
                                  <div className="search-Bar">
                                    <input
                                      type="text"
                                      value={query}
                                      onChange={(e) => setQuery(e.target.value)}
                                      placeholder="Enter your search name"
                                      className="search-b"
                                    />
                                    <button className="search-btn" onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                      <path d="M17.7441 17.0112L12.8596 12.1267C14.0334 10.7176 14.6187 8.91014 14.4938 7.08042C14.3689 5.25071 13.5434 3.53959 12.1891 2.30302C10.8347 1.06645 9.05571 0.399634 7.22221 0.441294C5.38872 0.482954 3.64187 1.22988 2.34505 2.52669C1.04824 3.82351 0.301314 5.57036 0.259653 7.40385C0.217993 9.23735 0.884807 11.0163 2.12138 12.3707C3.35795 13.7251 5.06907 14.5506 6.89878 14.6755C8.7285 14.8004 10.5359 14.215 11.9451 13.0413L16.8296 17.9258L17.7441 17.0112ZM1.57451 7.57722C1.57451 6.42592 1.91591 5.30048 2.55554 4.34321C3.19517 3.38594 4.10429 2.63984 5.16795 2.19925C6.23161 1.75867 7.40203 1.6434 8.53121 1.868C9.66039 2.09261 10.6976 2.64701 11.5117 3.4611C12.3258 4.27519 12.8802 5.31241 13.1048 6.44158C13.3294 7.57076 13.2141 8.74118 12.7735 9.80484C12.333 10.8685 11.5869 11.7776 10.6296 12.4173C9.67232 13.0569 8.54688 13.3983 7.39558 13.3983C5.85226 13.3966 4.37264 12.7827 3.28135 11.6914C2.19006 10.6002 1.57622 9.12053 1.57451 7.57722Z" fill="#283C55" fill-opacity="0.5" />
                                    </svg> Search</button>
                                  </div>
                                  <br></br>
                                  <br></br>
                                  <div class="container con-hide">
                                    <div class="row">
                                      <div class="col-3">
                                        <p className="week-table-heading">Name</p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">Contact</p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">
                                          Username
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p className="week-table-heading">Group</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="srcoll">
                                    <p className="b-bottom"></p>
                                    {results.map((result) => (
                                      <div class="container">
                                        <div class="row week-2">
                                          <div class="col-3">
                                            <div className="week-table-heading1">
                                              {result?.profilePicture?.fileName ? (
                                                // Render the profile picture if fileName is not null
                                                <img src={'https://api.mastertattooinstitute.com/' + result.profilePicture.fileName} style={{ width: '15%' }} alt="Profile" />
                                              ) : (
                                                // Render another picture or a default image when fileName is null
                                                <img src={pio} alt="Default" style={{ width: '15%' }} />
                                              )}{" "}
                                              {result.firstName + " " + result.lastName}
                                              <span
                                                style={{
                                                  color: "orange",
                                                  display: "block",
                                                  marginLeft: "30px",
                                                }}
                                              >
                                                Active
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-3">
                                            <p className="week-table-heading1">
                                              {result.phone}
                                            </p>
                                          </div>
                                          <div class="col-3">
                                            <p className="week-table-heading1">
                                              {result.username}
                                            </p>
                                          </div>
                                          <div class="col-2">
                                            <div className="checkboc">
                                              <p className="week-table-heading1">
                                                {result.username}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-1">
                                            <div className="checkboc d-flex align-items-center mt-1">
                                              <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                className="check-box"
                                                onChange={handleCheckboxChange12}
                                                value={result.id}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <p className="b-bottom"></p>
                                  </div>
                                  <br></br>
                                  <div className="pop-brn">
                                    <button className="cancel-btn" onClick={() => close()}>Cancel</button>
                                    <button className="assi-btn" onClick={handleButtonClick12} >Assign</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                    </div>
                    <hr></hr>
                    <div class="container weew">
                      <div class="row">
                        <div class="col-6">
                          <p className="week-table-heading">Name</p>
                        </div>
                        <div class="col-3">
                          <p className="week-table-heading">Phone</p>
                        </div>
                        <div class="col-3">
                          <p className="week-table-heading">Username</p>
                        </div>
                      </div>
                    </div>
                    <p className="b-bottom"></p>
                    {studentName.map((result) => (
                      <div class="container qk ">
                        <div class="row week-2">
                          <div class="col-6">
                            <p className="week-table-heading1 d-flex align-items-center">
                            {pic[result.id] ? (
                  <img src={'https://api.mastertattooinstitute.com/' + pic[result.id]} style={{ width: '50px',height:'50px',borderRadius:'50%',objectFit:'cover' }} alt={`Profile ${result.id}`} />
                ) : (
                  <img src={pio} alt={`Default ${result.id}`} style={{ width: '10%' }} />
                )}
                              <div style={{ marginLeft: '10px' }}>
                                {result.firstName + " " + result.lastName}
                                <span
                                  style={{
                                    color: "orange",
                                    display: "block",
                                  }}
                                >
                                  Active
                                </span>
                              </div>
                            </p>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <p className="week-table-heading1">
                              {result.phone}
                            </p>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <p className="week-table-heading1">
                              {result.username}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
