import React, { useState, useEffect } from "react";
import LOGO from "../assets/logo2.png";
import img1 from "../assets/Frame 3648.png";
import "./inbox.css";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faL } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Upernav from "./Upernav";


function Inbox() {
    const [loading, setLoading] = useState(true);
    const [isUlVisible, setIsUlVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("chat");

    const showUserContent = () => {
        setActiveTab("group");
    };

    const showContent = () => {
        setActiveTab("chat");
    };
    const toggleUlVisibility = () => {
        setIsUlVisible(!isUlVisible);
    };
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };
    useEffect(() => {
        setLoading(false);
    }, [])
    return (
        <div className="body-background">
            <div className="full-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="fixer">
                                <div className="data">
                                    <img src={LOGO} />
                                </div>
                                <div className="leftnav">
                                    <button onClick={toggleUlVisibility}>
                                        <FontAwesomeIcon
                                            className="bars"
                                            icon={faBars}
                                            style={{ color: "#283C55" }}
                                        />
                                    </button>
                                    <ul
                                        className="leftnav-1 padder"
                                        style={{ display: isUlVisible ? "none" : "block" }}
                                    >
                                        <li>
                                            <Link to="/welcomepage">
                                                <FontAwesomeIcon
                                                    icon={faTableColumns}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Dashboard</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/inbox'} className="isactive">
                                                <FontAwesomeIcon
                                                    icon={faInbox}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Inbox</h2>
                                            </Link>
                                        </li>
                                        <hr />
                                        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link to="/content">
                                                <FontAwesomeIcon
                                                    icon={faClipboard}
                                                    style={{ color: "#283C55", marginBottom: "10px" }}
                                                />
                                                <h2>Content</h2>
                                            </Link>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none" style={{ marginTop: '7px' }}>
                                                <path d="M15 1L8 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </li>
                                        <li>
                                            <Link to="/studentsp">
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Explore Student Portfolio</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/studentreg">
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Users</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul
                                        className="leftnav-2 padder"
                                        style={{ display: isUlVisible ? "none" : "block", padding: '0px 10px' }}
                                    >
                                        <li>
                                            <Link to={'/help'}>
                                                <FontAwesomeIcon
                                                    icon={faCircleInfo}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Help</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/settings'}>
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    style={{ color: "#283C55" }}
                                                />
                                                <h2>Settings</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className="div-style">
                                <div className="container">
                                    <Upernav />
                                </div>
                                <div className="container-fuild">
                                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                                </div>
                                {loading ? (
                                    <div className="kkw">
                                        <span class="loaders"></span>
                                    </div>
                                ) : (
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-sm-12">
                                                <div className="list-one">
                                                    <div className="toggle-head">
                                                        <div className="row toggle-head">
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <button className={activeTab === "chat" ? "active-tab10" : "btn-texture1"} onClick={showContent}>Chat</button>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <button className={activeTab === "group" ? "active-tab10" : "btn-texture1"} onClick={showUserContent}>Groups</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="chat-list-1" style={{ display: activeTab === "chat" ? "block" : "none" }}>
                                                        <br />
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Andrew Lahm</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>John Root</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Alex Hales</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                    {/* <ul className="group-list-1">
                    <li>TWO</li>
                  </ul> */}
                                                    <ul className="chat-list-1" style={{ display: activeTab === "group" ? "block" : "none" }}>
                                                        <br />
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Bootcamp</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Body Piercing (Inter..</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Tattoo Course</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Bootcamp</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Body Piercing (Inter..</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Tattoo Course</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Bootcamp</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Body Piercing (Inter..</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                        <li style={{ listStyle: 'none' }}>
                                                            <div className="chat-msgg">
                                                                <div className="chat-msgg-1">
                                                                    <img src={img1} alt="" />
                                                                    <div>
                                                                        <h2>Tattoo Course</h2> <h3>Lorem ipsum dolor sit...</h3>
                                                                    </div>
                                                                </div>
                                                                <h4>11:00 AM</h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-mb-12 col-sm-12 buble">
                                                <div className="center-chat">
                                                    <div className="chat-header">
                                                        <img src={img1} alt="" />
                                                        <h2>Andrew Lahm</h2>
                                                    </div>{" "}
                                                    <div className="chat-main">
                                                        <div className="2nd-person message-1">
                                                            <img src={img1} alt="" />
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                        </div>
                                                        <div className="1st-person message-2">
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                            <img src={img1} alt="" />
                                                        </div>
                                                        <div className="2nd-person message-1">
                                                            <img src={img1} alt="" />
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                        </div>
                                                        <div className="1st-person message-2">
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                            <img src={img1} alt="" />
                                                        </div>
                                                        <div className="2nd-person message-1">
                                                            <img src={img1} alt="" />
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                        </div>
                                                        <div className="1st-person message-2">
                                                            <h3>
                                                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                                                                Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                                                Lorem Ipsum
                                                            </h3>
                                                            <img src={img1} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Inbox