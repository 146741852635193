import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "./foldclick.css";
import LOGO from "../assets/logo2.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import IMG from "../assets/Frame 3669.png";
import FRAME from "../assets/Frame 3619.png";
import Profilepic from "../assets/Ellipse 3.svg";
import { Link } from "react-router-dom";

function Folderclick() {
  const [isUlVisible, setIsUlVisible] = useState(false);

  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [file, setFile] = useState();

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div className="body-background">
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="data">
                <img src={LOGO} />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="container">
                <div className="div-style">
                  <div className="data-flec">
                    <div className="name">
                      <h2 className="welcome-name">
                        Welcome, Steve
                        <span className="description">
                          Lorem Ipsum sit amet dolor metier consecture{" "}
                        </span>
                      </h2>
                    </div>
                    <div className="icons-flex">
                      <div className="icons">
                        <div className="drop-down">
                          <div class="dropdown">
                            <svg
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="36"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="app-svg"
                            >
                              <g clip-path="url(#clip0_340_447)">
                                <path
                                  d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2H15.73C14.14 2 13.5 2.64 13.5 4.23V8.27C13.5 9.86 14.14 10.5 15.73 10.5H19.77C21.36 10.5 22 9.86 22 8.27Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.5 17.5H20.5"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M17.5 20.5V14.5"
                                  stroke="#283C55"
                                  stroke-opacity="0.7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_340_447">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>

                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item courses">Courses</a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Course</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Quiz</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">New Manuals</p>
                                  </div>
                                </a>
                              </li>
                              <hr></hr>
                              <li>
                                <a class="dropdown-item courses">Users</a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">Add New User</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">
                                      Create New Group
                                    </p>
                                  </div>
                                </a>
                              </li>
                              <hr></hr>
                              <li>
                                <a class="dropdown-item courses">
                                  Files & Folders
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">Upload File</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item hov" href="#">
                                  <div style={{ display: "flex" }}>
                                    <i
                                      class="fa-solid fa-plus"
                                      style={{ color: "#e79f04" }}
                                    ></i>
                                    &nbsp;&nbsp;{" "}
                                    <p className="newcourse">
                                      Create New Folders
                                    </p>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="bell-icons">
                        <div className="drop-down">
                          <div class="dropdown">
                            <svg
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              xmlns="http://www.w3.org/2000/svg"
                              width="39"
                              height="38"
                              viewBox="0 0 39 38"
                              fill="none"
                            >
                              <path
                                d="M30.1769 21.2334L28.0082 19.0647V16.1918C28.0058 14.2064 27.2672 12.2925 25.9354 10.8201C24.6036 9.34776 22.7731 8.42149 20.798 8.22046V6.57812H19.1957V8.22046C17.2206 8.42149 15.3901 9.34776 14.0583 10.8201C12.7265 12.2925 11.9879 14.2064 11.9855 16.1918V19.0647L9.81677 21.2334C9.66651 21.3836 9.58208 21.5873 9.58203 21.7998V24.2032C9.58203 24.4157 9.66644 24.6194 9.81668 24.7697C9.96692 24.9199 10.1707 25.0043 10.3832 25.0043H15.9911V25.6268C15.9737 26.6432 16.332 27.6302 16.9972 28.3988C17.6625 29.1674 18.5879 29.6635 19.5963 29.7919C20.1532 29.8472 20.7155 29.7852 21.2471 29.6101C21.7787 29.4349 22.2677 29.1505 22.6827 28.775C23.0977 28.3995 23.4295 27.9413 23.6569 27.4299C23.8842 26.9185 24.0019 26.3651 24.0025 25.8055V25.0043H29.6105C29.823 25.0043 30.0268 24.9199 30.177 24.7697C30.3273 24.6194 30.4117 24.4157 30.4117 24.2032V21.7998C30.4116 21.5873 30.3272 21.3836 30.1769 21.2334ZM22.4003 25.8055C22.4003 26.4429 22.147 27.0542 21.6963 27.5049C21.2456 27.9557 20.6343 28.2089 19.9968 28.2089C19.3594 28.2089 18.7481 27.9557 18.2974 27.5049C17.8466 27.0542 17.5934 26.4429 17.5934 25.8055V25.0043H22.4003V25.8055ZM28.8094 23.4021H11.1843V22.1314L13.353 19.9628C13.5032 19.8126 13.5877 19.6088 13.5877 19.3964V16.1918C13.5877 14.492 14.263 12.8618 15.4649 11.6599C16.6669 10.4579 18.297 9.78268 19.9968 9.78268C21.6966 9.78268 23.3268 10.4579 24.5288 11.6599C25.7307 12.8618 26.406 14.492 26.406 16.1918V19.3964C26.406 19.6088 26.4904 19.8126 26.6407 19.9628L28.8094 22.1314V23.4021Z"
                                fill="#283C55"
                                fill-opacity="0.7"
                              />
                              <rect
                                x="1.08657"
                                y="0.274074"
                                width="36.7259"
                                height="36.7259"
                                rx="1.91852"
                                stroke="#283C55"
                                stroke-opacity="0.5"
                                stroke-width="0.548148"
                              />
                            </svg>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item bell1" href="#">
                                  <i class="fa-regular fa-bell"></i>
                                  &nbsp;Notifications
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <div style={{ display: "flex" }}>
                                    <p className="noti-profile"></p>
                                    &nbsp;&nbsp; <p>new post</p>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="#">
                                  <div style={{ display: "flex" }}>
                                    <p className="noti-profile"></p>
                                    &nbsp;&nbsp; <p>new post</p>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item onhover" href="#">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View All
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="search-bar">
                        <Popup
                          trigger={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                              fill="none"
                            >
                              <path
                                d="M29.2294 27.4708L23.7172 21.9586C25.0419 20.3684 25.7024 18.3287 25.5614 16.2639C25.4205 14.199 24.4889 12.268 22.9605 10.8726C21.4321 9.47709 19.4245 8.72459 17.3554 8.77161C15.2863 8.81862 13.315 9.66153 11.8515 11.125C10.3881 12.5884 9.54519 14.5598 9.49817 16.6289C9.45116 18.698 10.2037 20.7055 11.5991 22.234C12.9946 23.7624 14.9256 24.6939 16.9904 24.8349C19.0553 24.9758 21.0949 24.3153 22.6852 22.9907L28.1974 28.5029L29.2294 27.4708ZM10.982 16.8245C10.982 15.5253 11.3673 14.2552 12.0891 13.1749C12.8109 12.0946 13.8368 11.2527 15.0372 10.7555C16.2375 10.2583 17.5584 10.1282 18.8326 10.3817C20.1069 10.6351 21.2774 11.2608 22.1961 12.1795C23.1148 13.0982 23.7405 14.2687 23.9939 15.5429C24.2474 16.8172 24.1173 18.138 23.6201 19.3384C23.1229 20.5387 22.2809 21.5647 21.2007 22.2865C20.1204 23.0083 18.8503 23.3936 17.5511 23.3936C15.8094 23.3917 14.1397 22.6989 12.9082 21.4674C11.6766 20.2359 10.9839 18.5661 10.982 16.8245Z"
                                fill="#283C55"
                                fill-opacity="0.7"
                              />
                              <rect
                                x="1.00064"
                                y="0.274074"
                                width="36.7259"
                                height="36.7259"
                                rx="1.91852"
                                stroke="#283C55"
                                stroke-opacity="0.5"
                                stroke-width="0.548148"
                              />
                            </svg>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="popup-style">
                              <div>
                                <div>
                                  <div className="search">
                                    <i class="fa-solid fa-magnifying-glass sear"></i>
                                    <input
                                      type="search"
                                      className="searchstyle"
                                      placeholder="Search content, user, etc"
                                    />
                                    <i
                                      class="fa-solid fa-xmark sear"
                                      onClick={() => close()}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </div>
                                  <hr></hr>
                                  <h2 className="recent-result">
                                    Recent Result
                                  </h2>
                                  <div className="result">
                                    <a href="" className="result1">
                                      User
                                    </a>
                                    <a href="" className="result1">
                                      Content
                                    </a>
                                    <a href="" className="result1">
                                      Groups
                                    </a>
                                  </div>
                                  <hr></hr>
                                  <div className="srcoll">
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                    <br></br>
                                    <div className="cards1">
                                      <img src={IMG} />
                                      <p className="title">
                                        Course Title -<br></br>
                                        <span className="public">
                                          <i class="fa-regular fa-eye"></i>
                                          &nbsp;&nbsp;Public
                                        </span>
                                        <span className="public">
                                          <i class="fa-regular fa-user"></i>
                                          &nbsp;&nbsp;27 lectures
                                        </span>
                                        <span className="public">
                                          <i class="fa-solid fa-paintbrush"></i>
                                          &nbsp;&nbsp;Design
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                      <p className="line">&nbsp;</p>
                      <div className="signup">
                        <div className="sign-img">
                          <img
                            src={Profilepic}
                            className="profilepic"
                            alt="Profile-Pic"
                          />
                        </div>
                        <div className="drop-down">
                          <div class="dropdown">
                            <a
                              class=" dropdown-toggle admin-name"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Steve Roe
                            </a>
                            <span className="admin-check">Admin</span>

                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a class="dropdown-item onhover" href="#">
                                  <i class="fa-regular fa-user"></i>&nbsp;My
                                  Profile
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="fa-light fa-arrow-right-to-bracket"></i>
                                  &nbsp;SignOut
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="leftnav">
                <button onClick={toggleUlVisibility}>
                  <FontAwesomeIcon
                    className="bars"
                    icon={faBars}
                    style={{ color: "#283C55" }}
                  />
                </button>
                <ul
                  className="leftnav-1"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <Link to="/welcomepage">
                      <FontAwesomeIcon
                        icon={faTableColumns}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Dashboard</h2>
                    </Link>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faInbox}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Inbox</h2>
                    </a>
                  </li>
                  <hr />
                  <li>
                    <Link to="/content" className="isactive">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Content</h2>
                    </Link>
                    <ul className="sub-tabs-list">
                      <li className="sub-tab">
                        <Link to="/content">Courses</Link>
                      </li>
                      <li className="sub-tab">
                        <Link to="/quiz">Quiz</Link>
                      </li>
                      <li className="sub-tab">
                        <Link to="/addnewassignment">Assignments</Link>
                      </li>
                      <li className="sub-tab isactive1">
                        <Link to="/folder">Folders</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/studentsp">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Explore Student Portfolio</h2>
                    </Link>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Users</h2>
                    </a>
                  </li>
                </ul>
                <ul
                  className="leftnav-2"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Help</h2>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Settings</h2>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="container back">
                <div className="content-header">
                  <div className="content-folders">
                    <h2>
                      <em>15</em> Files
                    </h2>
                  </div>
                  <div className="content-controls">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#283C5580" }}
                    />
                    <h2>All status</h2>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faFilter}
                        style={{ color: "#283C5580" }}
                      />
                      <h2>Filter</h2>
                    </a>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faSort}
                        style={{ color: "#283C5580" }}
                      />
                      <h2>Sort</h2>
                    </a>
                  </div>
                </div>

                <div className="row no-gutter">
                  <div className="folders-list">
                    <div className="row">
                      <br></br>
                      <div className="add-newcourse">
                        <form>
                          <input type="file" onChange={handleChange} />
                          <br></br>
                          <button type="submit" className="upload-file">
                            Upload
                          </button>
                        </form>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 no-gutter">
                        <a href="">
                          <div className="file">
                            <div className="file-div">
                              <FontAwesomeIcon
                                className="file-icon"
                                icon={faFolder}
                                style={{ color: "#283C5580" }}
                              />
                            </div>
                            <div className="file-name">FIleName.png</div>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 no-gutter">
                        <a href="">
                          <div className="file">
                            <div className="file-div">
                              <FontAwesomeIcon
                                className="file-icon"
                                icon={faFolder}
                                style={{ color: "#283C5580" }}
                              />
                            </div>
                            <div className="file-name">FIleName.png</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Folderclick;
