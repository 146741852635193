import React, { useState, useEffect } from "react";
import "./welcome.css";
import LOGO from "../assets/logo2.png";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faL } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, BarElement,
  CategoryScale
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Upernav from "./Upernav";
import Rec from "../assets/Rectangle 108.png";
import Rec1 from "../assets/Rectangle 108 (1).png";
import Rec2 from "../assets/Rectangle 108 (2).png";
import Rec3 from "../assets/Rectangle 108 (3).png";
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
)
ChartJS.register(ArcElement, Tooltip, Legend);
export default function Welcomepage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const data1 = {
    labels: ["sat", "sun", "mon", "tue", "wed", "thu", "fri"],
    datasets: [{
      data: [10, , 5, 12, , 8],
      backgroundColor: 'red',
      label: 'Completion Rate'
    },
    {
      data: [6, 3],
      label: 'In Progress',
      backgroundColor: 'aqua'
    }
    ]
  };
  const [activeTab, setActiveTab] = useState("grid");
  const showUserContent = () => {
    setActiveTab("grid");
  };
  const showContent = () => {
    setActiveTab("list");
  };
  const [stats, setDatanew] = useState('');
  const [tokenData, settokenData] = useState('');
  const [quizCount, setquizCount] = useState('');
  const [assignmentCount, setassignmentCount] = useState('');
  const [weeksCount, setweekCount] = useState('');
  const [recentCourse1, setRecentCourse] = useState([]);
  const [totalInstructor, setInstructor] = useState('');
  const [totalCourse, setCourse] = useState('');
  const [loading, setLoading] = useState(true);
  const Token = localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.mastertattooinstitute.com/api/v1/user/adminStats', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setDatanew(result.usersCount);
        setquizCount(result.quizCount);
        setassignmentCount(result.assignmentCount);
        setweekCount(result.weeksCount);
        settokenData(result.tokenCount);
        setRecentCourse(result.recentCourses);
        setCourse(result.totalCourses);
        setInstructor(result.totalInstructors);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const data = {
    datasets: [
      {
        data: [10, 2, 3, 5, 8],
        backgroundColor: [" #283C55", "#FF912C", "#F4D1C6", "#85A3AB", "#AEDCE8"],
        borderColor: ["#283C55", "#283C55"],
        text: "100",
      },
    ],
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  const options = {
    events: []
  };
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className="body-background">
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="fixer">
                <div className="data">
                  <img src={LOGO} />
                </div>
                <div className="leftnav">
                  <button onClick={toggleUlVisibility}>
                    <FontAwesomeIcon
                      className="bars"
                      icon={faBars}
                      style={{ color: "#283C55" }}
                    />
                  </button>
                  <ul
                    className="leftnav-1 padder"
                    style={{ display: isUlVisible ? "none" : "block" }}
                  >
                    <li>
                      <Link to="/welcomepage" className="isactive">
                        <FontAwesomeIcon
                          icon={faTableColumns}
                          style={{ color: "#283C55", marginBottom: "10px" }}
                        />
                        <h2>Dashboard</h2>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/inbox'}>
                        <FontAwesomeIcon
                          icon={faInbox}
                          style={{ color: "#283C55", marginBottom: "10px" }}
                        />
                        <h2>Inbox</h2>
                      </Link>
                    </li>
                    <hr />
                    <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Link to="/content">
                        <FontAwesomeIcon
                          icon={faClipboard}
                          style={{ color: "#283C55", marginBottom: "10px" }}
                        />
                        <h2>Content</h2>
                      </Link>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none" style={{ marginTop: '7px' }}>
                        <path d="M15 1L8 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </li>
                    <li>
                      <Link to="/studentsp">
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ color: "#283C55" }}
                        />
                        <h2>Explore Student Portfolio</h2>
                      </Link>
                    </li>
                    <li>
                      <Link to="/studentreg">
                        <FontAwesomeIcon
                          icon={faUsers}
                          style={{ color: "#283C55" }}
                        />
                        <h2>Users</h2>
                      </Link>
                    </li>
                  </ul>
                  <ul
                    className="leftnav-2 padder"
                    style={{ display: isUlVisible ? "none" : "block", padding: '0px 10px' }}
                  >
                    <li>
                      <Link to={'/help'}>
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ color: "#283C55" }}
                        />
                        <h2>Help</h2>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/settings'}>
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{ color: "#283C55" }}
                        />
                        <h2>Settings</h2>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="div-style">
                <div className="container">
                  <Upernav />
                </div>
                <div className="container-fuild">
                  <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)' }}></p>
                </div>
                {loading ? (
                  <div className="kkw">
                    <span class="loaders"></span>
                  </div>
                ) : (
                  <div className="container back">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 new_size">
                        <div className="row">
                          <div className="t-user">
                            <div className="total-users">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 69 69"
                                fill="none"
                              >
                                <circle
                                  cx="34.5"
                                  cy="34.5"
                                  r="34.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                            <div className="use">
                              <p className="users">
                                Total User{" "}
                                <span className="numbers">{stats} Users</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="t-user">
                            <div className="total-users">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 69 69"
                                fill="none"
                              >
                                <circle
                                  cx="34.5"
                                  cy="34.5"
                                  r="34.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                            <div className="use">
                              <p className="users">
                                Total Instructors
                                <span className="numbers">{totalInstructor} Instructor</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="t-user">
                            <div className="total-users">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 69 69"
                                fill="none"
                              >
                                <circle
                                  cx="34.5"
                                  cy="34.5"
                                  r="34.5"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                            <div className="use">
                              <p className="users">
                                Total Courses
                                <span className="numbers">{totalCourse} Courses</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 new_size">
                        <div className="pie-char">
                          <p className="content">Total Contents</p>
                          <div className="div" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="doughnut">
                              <Doughnut data={data} options={options}></Doughnut>
                            </div>
                          </div>
                          <div className="contanier">
                            <div className="row mt-3">
                              <div className="col-lg-7">
                                <h6 className="new_style" style={{ fontSize: '13px' }}><img src={Rec} /> {assignmentCount} Assignment</h6>
                              </div>
                              <div className="col-lg-5"><h6 className="new_style" style={{ fontSize: '13px' }}><img src={Rec1} /> {quizCount} Quizes</h6></div>
                            </div>
                          </div>
                          <div className="contanier">
                            <div className="row mt-3">
                              <div className="col-lg-7">
                                <h6 className="new_style" style={{ fontSize: '13px' }}><img src={Rec3} /> {tokenData} Tokens</h6>
                              </div>
                              <div className="col-lg-5">
                                <h6 className="new_style" style={{ fontSize: '13px' }}><img src={Rec2} /> {weeksCount} weeks</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-8 col-sm-12 new_size">
                        <div className="borderofcontent">
                          <div class="scrollable-div">
                            <h2 className="Recent">Recent Student Activity</h2>
                            <br></br>
                            {recentCourse1?.map((item) => (
                              <li style={{ listStyle: 'none' }}>
                                <div className="flexer">
                                  <div className="image"></div>
                                  <div className="textq">
                                    {item?.student?.firstName ? (
                                      <p className="qw">
                                        {item.student.firstName}
                                        <span className="er"> just {item.status} this</span>{" "}
                                        {item?.course?.title ? (
                                          <span className="wq">Course Title - {item.course.title}</span>
                                        ) : (<span className="wq">Course Title - no title</span>)
                                        }
                                        <span className="ab">
                                          <i class="fa fa-calendar-days"></i>
                                          &nbsp;&nbsp;{formatDate(item.createdAt)}
                                        </span>
                                      </p>) : (<p>no one</p>)}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="student-insight">
                        <h2 className="student">Student Insight</h2>
                        <div className="leaner">
                          <div className="active-learner" style={{ marginRight: '20px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M2.5 2.75C2.5 2.55109 2.42098 2.36032 2.28033 2.21967C2.13968 2.07902 1.94891 2 1.75 2C1.55109 2 1.36032 2.07902 1.21967 2.21967C1.07902 2.36032 1 2.55109 1 2.75V21.25C1 21.664 1.336 22 1.75 22H20C20.1989 22 20.3897 21.921 20.5303 21.7803C20.671 21.6397 20.75 21.4489 20.75 21.25C20.75 21.0511 20.671 20.8603 20.5303 20.7197C20.3897 20.579 20.1989 20.5 20 20.5H2.5V2.75Z"
                                fill="#FF912C"
                              />
                              <path
                                d="M22.2792 7.78082C22.4117 7.63865 22.4838 7.4506 22.4804 7.2563C22.4769 7.062 22.3982 6.87661 22.2608 6.7392C22.1234 6.60179 21.938 6.52308 21.7437 6.51965C21.5494 6.51622 21.3614 6.58834 21.2192 6.72082L15.4992 12.4408L11.7792 8.72082C11.6386 8.58037 11.4479 8.50148 11.2492 8.50148C11.0504 8.50148 10.8598 8.58037 10.7192 8.72082L4.71918 14.7208C4.64549 14.7895 4.58639 14.8723 4.5454 14.9643C4.5044 15.0563 4.48236 15.1556 4.48059 15.2563C4.47881 15.357 4.49733 15.457 4.53505 15.5504C4.57278 15.6438 4.62892 15.7286 4.70014 15.7999C4.77136 15.8711 4.85619 15.9272 4.94958 15.9649C5.04297 16.0027 5.143 16.0212 5.2437 16.0194C5.3444 16.0176 5.44372 15.9956 5.53571 15.9546C5.62771 15.9136 5.71052 15.8545 5.77918 15.7808L11.2492 10.3108L14.9692 14.0308C15.1098 14.1713 15.3004 14.2502 15.4992 14.2502C15.6979 14.2502 15.8886 14.1713 16.0292 14.0308L22.2792 7.78082Z"
                                fill="#FF912C"
                              />
                            </svg>
                            <h2 className="active">
                              <span className="ac">&nbsp;&nbsp;2</span> Active
                              Learner
                            </h2>
                          </div>
                          <div className="completion-rate">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_628_936)">
                                <path
                                  d="M4.7513 10.293H1.58464C1.10964 10.293 0.792969 10.6096 0.792969 11.0846V17.418C0.792969 17.893 1.10964 18.2096 1.58464 18.2096H4.7513C5.2263 18.2096 5.54297 17.893 5.54297 17.418V11.0846C5.54297 10.6096 5.2263 10.293 4.7513 10.293ZM17.418 7.1263H14.2513C13.7763 7.1263 13.4596 7.44297 13.4596 7.91797V17.418C13.4596 17.893 13.7763 18.2096 14.2513 18.2096H17.418C17.893 18.2096 18.2096 17.893 18.2096 17.418V7.91797C18.2096 7.44297 17.893 7.1263 17.418 7.1263ZM11.0846 0.792969H7.91797C7.44297 0.792969 7.1263 1.10964 7.1263 1.58464V17.418C7.1263 17.893 7.44297 18.2096 7.91797 18.2096H11.0846C11.5596 18.2096 11.8763 17.893 11.8763 17.418V1.58464C11.8763 1.10964 11.5596 0.792969 11.0846 0.792969Z"
                                  fill="#85A3AB"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_628_936">
                                  <rect width="19" height="19" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <h2 className="active">
                              <span className="ac">&nbsp;&nbsp;1%</span>Completion
                              Rate
                            </h2>
                          </div>
                        </div>
                        {/* <div className="drop-down1">
                      <div className="dropdown-menu3">
                        <select id="cars" name="cars" className="drop">
                          <option value="volvo">Courses</option>
                          <option value="saab">BSSE</option>
                          <option value="fiat">BSCS</option>
                          <option value="audi">BSIT</option>
                        </select>
                      </div>
                      <div className="dropdown-menu2">
                        <select id="cars" name="cars" className="drop">
                          <option value="volvo">Daily</option>
                          <option value="saab">BSSE</option>
                          <option value="fiat">BSCS</option>
                          <option value="audi">BSIT</option>
                        </select>
                      </div>
                    </div> */}
                      </div>
                      <div className="content-write new_bar">
                        <Bar data={data1} options={options}
                          style={{
                            padding: '10px',
                            width: 'auto'
                          }} className="new_bar"></Bar>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              {/* <div className="leftnav">
                <button onClick={toggleUlVisibility}>
                  <FontAwesomeIcon
                    className="bars"
                    icon={faBars}
                    style={{ color: "#283C55" }}
                  />
                </button>
                <ul
                  className="leftnav-1"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <Link to="/welcomepage" className="isactive">
                      <FontAwesomeIcon
                        icon={faTableColumns}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Dashboard</h2>
                    </Link>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faInbox}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Inbox</h2>
                    </a>
                  </li>
                  <hr />
                  <li>
                    <Link to="/content">
                      <FontAwesomeIcon
                        icon={faClipboard}
                        style={{ color: "#283C55", marginBottom: "10px" }}
                      />
                      <h2>Content</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentsp">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Explore Student Portfolio</h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/studentreg">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Users</h2>
                    </Link>
                  </li>
                </ul>
                <ul
                  className="leftnav-2"
                  style={{ display: isUlVisible ? "none" : "block" }}
                >
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Help</h2>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ color: "#283C55" }}
                      />
                      <h2>Settings</h2>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              {/* <div className="container-fluid back">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="row">
                      <div className="t-user">
                        <div className="total-users">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 69 69"
                            fill="none"
                          >
                            <circle
                              cx="34.5"
                              cy="34.5"
                              r="34.5"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div className="use">
                          <p className="users">
                            Total User{" "}
                            <span className="numbers">{stats} Users</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="t-user">
                        <div className="total-users">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 69 69"
                            fill="none"
                          >
                            <circle
                              cx="34.5"
                              cy="34.5"
                              r="34.5"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div className="use">
                          <p className="users">
                            Total Instructors
                            <span className="numbers">{totalInstructor} Instructor</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="t-user">
                        <div className="total-users">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 69 69"
                            fill="none"
                          >
                            <circle
                              cx="34.5"
                              cy="34.5"
                              r="34.5"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div className="use">
                          <p className="users">
                            Total Courses
                            <span className="numbers">{totalCourse} Courses</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="pie-char">
                      <p className="content">Total Contents</p>
                        <div className="div" style={{display:'flex',justifyContent:'center'}}>
                      <div style={{ width: "60%", height: "60%",marginTop:'20px'}}>
                        <Doughnut data={data} options={options}></Doughnut>
                      </div>
                      </div>
                      <div className="div" style={{textAlign:'center',marginTop:'31px'}}>
                        <div className="ipard">
                        <h6>{assignmentCount} Assignment</h6>
                        <h6>{quizCount} Quizes</h6>
                        </div>
                        <div className="2part">
                        <h6 style={{marginLeft:'20px'}}>{weeksCount} weeks</h6>
                        <h6>{tokenData} Tokens</h6>
                        </div>
                        </div>
                      <p style={{marginTop:'-7px'}}></p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-8 col-sm-12">
                    <div className="borderofcontent">
                      <div class="scrollable-div">
                        <h2 className="Recent">Recent Student Activity</h2>
                        <br></br>
                        {recentCourse.map((item, index) => (
                        <li style={{listStyle:'none'}}>
                        <div className="flexer">
                          <div className="image"></div>
                          <div className="textq">
                            <p className="qw">
                              {item.student.firstName}{" "}
                              <span className="er"> just {item.status} this</span>{" "}
                              <span className="wq">Course Title - {item.course.title}</span>{" "}
                              <span className="ab">
                                <i class="fa fa-calendar-days"></i>
                                &nbsp;&nbsp;{formatDate(item.createdAt)}
                              </span>
                            </p>
                          </div>
                        </div>
                       </li>
                            ))} 
                      </div>
                    </div>
                  </div>
                  <div className="student-insight">
                    <h2 className="student">Student Insight</h2>
                    <div className="active-learner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2.5 2.75C2.5 2.55109 2.42098 2.36032 2.28033 2.21967C2.13968 2.07902 1.94891 2 1.75 2C1.55109 2 1.36032 2.07902 1.21967 2.21967C1.07902 2.36032 1 2.55109 1 2.75V21.25C1 21.664 1.336 22 1.75 22H20C20.1989 22 20.3897 21.921 20.5303 21.7803C20.671 21.6397 20.75 21.4489 20.75 21.25C20.75 21.0511 20.671 20.8603 20.5303 20.7197C20.3897 20.579 20.1989 20.5 20 20.5H2.5V2.75Z"
                          fill="#FF912C"
                        />
                        <path
                          d="M22.2792 7.78082C22.4117 7.63865 22.4838 7.4506 22.4804 7.2563C22.4769 7.062 22.3982 6.87661 22.2608 6.7392C22.1234 6.60179 21.938 6.52308 21.7437 6.51965C21.5494 6.51622 21.3614 6.58834 21.2192 6.72082L15.4992 12.4408L11.7792 8.72082C11.6386 8.58037 11.4479 8.50148 11.2492 8.50148C11.0504 8.50148 10.8598 8.58037 10.7192 8.72082L4.71918 14.7208C4.64549 14.7895 4.58639 14.8723 4.5454 14.9643C4.5044 15.0563 4.48236 15.1556 4.48059 15.2563C4.47881 15.357 4.49733 15.457 4.53505 15.5504C4.57278 15.6438 4.62892 15.7286 4.70014 15.7999C4.77136 15.8711 4.85619 15.9272 4.94958 15.9649C5.04297 16.0027 5.143 16.0212 5.2437 16.0194C5.3444 16.0176 5.44372 15.9956 5.53571 15.9546C5.62771 15.9136 5.71052 15.8545 5.77918 15.7808L11.2492 10.3108L14.9692 14.0308C15.1098 14.1713 15.3004 14.2502 15.4992 14.2502C15.6979 14.2502 15.8886 14.1713 16.0292 14.0308L22.2792 7.78082Z"
                          fill="#FF912C"
                        />
                      </svg>
                      <h2 className="active">
                        <span className="ac">&nbsp;&nbsp;216</span> Active
                        Learner
                      </h2>
                    </div>
                    <div className="completion-rate">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_628_936)">
                          <path
                            d="M4.7513 10.293H1.58464C1.10964 10.293 0.792969 10.6096 0.792969 11.0846V17.418C0.792969 17.893 1.10964 18.2096 1.58464 18.2096H4.7513C5.2263 18.2096 5.54297 17.893 5.54297 17.418V11.0846C5.54297 10.6096 5.2263 10.293 4.7513 10.293ZM17.418 7.1263H14.2513C13.7763 7.1263 13.4596 7.44297 13.4596 7.91797V17.418C13.4596 17.893 13.7763 18.2096 14.2513 18.2096H17.418C17.893 18.2096 18.2096 17.893 18.2096 17.418V7.91797C18.2096 7.44297 17.893 7.1263 17.418 7.1263ZM11.0846 0.792969H7.91797C7.44297 0.792969 7.1263 1.10964 7.1263 1.58464V17.418C7.1263 17.893 7.44297 18.2096 7.91797 18.2096H11.0846C11.5596 18.2096 11.8763 17.893 11.8763 17.418V1.58464C11.8763 1.10964 11.5596 0.792969 11.0846 0.792969Z"
                            fill="#85A3AB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_628_936">
                            <rect width="19" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <h2 className="active">
                        <span className="ac">&nbsp;&nbsp;72%</span>Completion
                        Rate
                      </h2>
                    </div>
                    <div className="drop-down1">
                      <div className="dropdown-menu3">
                        <select id="cars" name="cars" className="drop">
                          <option value="volvo">Courses</option>
                          <option value="saab">BSSE</option>
                          <option value="fiat">BSCS</option>
                          <option value="audi">BSIT</option>
                        </select>
                      </div>
                      <div className="dropdown-menu2">
                        <select id="cars" name="cars" className="drop">
                          <option value="volvo">Daily</option>
                          <option value="saab">BSSE</option>
                          <option value="fiat">BSCS</option>
                          <option value="audi">BSIT</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="content-write"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
}
