import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import img1 from "../assets/Frame 3648.png";
import { Link } from "react-router-dom";
import "./addstudent.css"; // Adjust the path based on your project structure

function Addteacherreg() {
  const [isUlVisible, setIsUlVisible] = useState(false);

  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="side-menu">
            <ul>
              <div className="back-button-div">
                <Link to="/teacherreg">
                  <FontAwesomeIcon
                    className="back-button"
                    icon={faCaretLeft}
                    style={{ color: "#283C55" }}
                  />
                  <h2>Back to Instructors List</h2>
                </Link>
              </div>
              <li className="active">
                <Link to="/addsteacherreg">Add Manually</Link>
              </li>
              <li>
                <Link to="/teachermail">E-mail Invite</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-9 col-md-6 col-sm-12">
          <div className="white-container">
            <div className="add-student-header">
              <h2>Add User</h2>
              <div className="preview-save">
                <div className="preview-btn">
                  <FontAwesomeIcon icon={faEye} style={{ color: "#283C55" }} />
                  <a href="">
                    <h3>Preview</h3>
                  </a>
                </div>
                <button>Save</button>
              </div>
            </div>
            <hr />
            <div className="user-roles">
              <h2>User Role</h2>
              <div>
                <input type="radio" value="" name="gender" /> Student
                <input type="radio" value="" name="gender" /> Instructor
                <input type="radio" value="" name="gender" /> Admin
              </div>
            </div>
            <div className="profile-picture">
              <h3>Profile Picture</h3>
              <div className="profile-pic">
                <img src={img1} alt="" />
                <button>Change Picture</button>
                <button>Remove</button>
              </div>
            </div>
            <div className="personal-info">
              <h3>Personal Information</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <h2>Full Name*</h2>
                    <input type="text" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Registration Number*</h2>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-6 col-md-10 col-sm-10">
                    <h2>Gender*</h2>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        value=""
                        name="gender"
                      />{" "}
                      Student
                      <input
                        className="radio"
                        type="radio"
                        value=""
                        name="gender"
                      />{" "}
                      Instructor
                      <input
                        className="radio"
                        type="radio"
                        value=""
                        name="gender"
                      />{" "}
                      Admin
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-info">
              <h3>Contact Information</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>E-mail*</h2>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Mobile Number*</h2>
                    <input type="text" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Country*</h2>
                    <select name="" id="">
                      <option value="">Country</option>
                      <option value="">Country</option>
                      <option value="">Country</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>State*</h2>
                    <input type="text" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>City*</h2>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Postal Code*</h2>
                    <input type="text" required />
                  </div>
                </div>
              </div>
            </div>

            <div className="educational-info">
              <h3>Educational Information</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Role*</h2>
                    <input type="text" required />
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Website*</h2>
                    <input type="text" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-10">
                    <h2>Skills</h2>
                    <div className="skills">
                      <h2>Sketching</h2>
                      <h2>Painting</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addteacherreg;
