import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLeaf, faUser } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import img1 from "../assets/Frame 3619.png";
import { Link } from "react-router-dom";
import "./studentsprofils.css";
import Group from "../assets/Group 26.png";
import { useParams } from "react-router-dom";
import LOGO from "../assets/logo2.png";
import Upernav from "./Upernav";
function Studentprofiles() {
  const [activeTab, setActiveTab] = useState("user");
  const showUserContent = () => {
    setActiveTab("user");
  };
  const showContent = () => {
    setActiveTab("content");
  };
  const showGroups = () => {
    setActiveTab("groups");
  };
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const { studentId } = useParams();
  const [Studentname, setStudentname] = useState('');
  const [studentPhone, setphone] = useState('');
  const [studentMail, setstudentMail] = useState('');
  const [studentRole, setRole] = useState('');
  const [studentRegistration, setRegistration] = useState('');
  const [Gender, setGender] = useState('');
  const [website, setwebsite] = useState('');
  const [education, seteducation] = useState('');
  const [degree, setdegree] = useState('');
  const [profilpic, setpicture] = useState('');
  const [skills, setskill] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(`https://api.mastertattooinstitute.com/api/v1/user/${studentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStudentname(data.firstName + data.lastName);
        localStorage.setItem("Fullname", data.firstName + data.lastName);
        setphone(data.phone);
        setstudentMail(data.email);
        setRole(data.role);
        setGender(data.gender);
        setRegistration(data.registerationNumber);
        setdegree(data.degree);
        setwebsite(data.website);
        seteducation(data.education);
        setskill(data.skills);
        setpicture(data.profilePicture.fileName)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [studentId]);
  const [courses, setcourse] = useState([]);
  const [compltedCount, setCount] = useState('')
  useEffect(() => {
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/studentCourse/student/${studentId}/fetch`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setcourse(data.courses);
        setCount(data.completedCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [studentId]);
  const extractFirst22Words1 = (paragraph) => {
    return paragraph.slice(0, 10);
  };
  return (
    <div className="body-background">
      {loading ? (
        <div className="kkw">
          <span class="loaders"></span>
        </div>
      ) : (
        <div className="full-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="fixer">
                  <div className="data">
                    <img src={LOGO} />
                  </div>
                  <div className="leftnav">
                    <button onClick={toggleUlVisibility}>
                      <FontAwesomeIcon
                        className="bars"
                        icon={faBars}
                        style={{ color: "#283C55" }}
                      />
                    </button>
                    <ul
                      className="leftnav-1 padder"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >
                      <li>
                        <Link to="/welcomepage">
                          <FontAwesomeIcon
                            icon={faTableColumns}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Dashboard</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                      <hr />
                      <li>
                        <Link to="/content">
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Content</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentsp">
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Explore Student Portfolio</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to="/studentreg" className="isactive">
                          <FontAwesomeIcon
                            icon={faUsers}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Users</h2>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className="leftnav-2 padder"
                      style={{ display: isUlVisible ? "none" : "block" }}
                    >

                      <li>
                        <Link to={'/help'}>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Help</h2>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/settings'}>
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ color: "#283C55" }}
                          />
                          <h2>Settings</h2>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="div-style">
                  <div className="container">
                    <Upernav />
                  </div>
                  <div className="container-fuild">
                    <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)', marginBottom: '5px', marginTop: '5px' }}></p>
                  </div>
                  <div className="container" style={{ marginTop: '10px' }}>
                    <Link to={'/studentreg'} className="back-course">
                      <img src={Group} />
                      &nbsp; Back to Students
                    </Link>
                  </div>
                  <div className="white-container">
                    <div className="profile-header">
                      <div className="profile-cover">
                        <div className="profile-img">
                          {profilpic ? (
                            // Render the profile picture if fileName is not null
                            <img src={'https://api.mastertattooinstitute.com/' + profilpic} alt="Profile" />
                          ) : (
                            // Render another picture or a default image when fileName is null
                            <img src={img1} alt="Default" />
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="profile-name">
                      <h2>{Studentname}</h2>
                    </div>
                    <div className="profile-content">
                      <div className="profile-tabs">
                        <ul>
                          <li className={activeTab === "user" ? "active-tab" : ""}>
                            <Link to={`/studentprofiles/${studentId}`} onClick={showUserContent}>
                              User Details
                            </Link>
                          </li>
                          <li
                            className={activeTab === "content" ? "active-tab" : ""}
                          >
                            <Link to="#" onClick={showContent}>
                              Content
                            </Link>
                          </li>
                          <li
                            className={activeTab === "groups" ? "active-tab" : ""}
                          >
                            <a href="#" onClick={showGroups}>
                              Groups
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{ display: activeTab === "user" ? "block" : "none" }}
                      >
                        <div className="user-details">
                          <h3>Personal Information</h3>
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                              <h2>Full Name</h2> <h1>{Studentname}</h1>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 no-gutter">
                              <h2>Registration Number</h2> <h1>{studentRegistration}</h1>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                              <h2>Gender</h2> <h1>{Gender}</h1>
                            </div>
                          </div>
                          <h3>Contact Information</h3>
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                              <h2>Phone Number</h2> <h1>{studentPhone}</h1>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 no-gutter">
                              <h2>Favroite Sports</h2> <h1>{website}</h1>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                              <h2>E-mail</h2> <h1>{studentMail}</h1>
                            </div>
                          </div>
                          <h3>Interest & Hobbies</h3>
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 no-gutter">
                              {education ? (
                                <>
                                  <h2>Favorite Music</h2>
                                  <h1>{education}</h1>
                                </>
                              ) : (
                                <h1>Not Added</h1>
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 no-gutter">
                            {degree ? (
                                <>
                                  <h2>Favorite Movies</h2>
                                  <h1>{degree}</h1>
                                </>
                              ) : (
                                <h1>Not Added</h1>
                              )}
                            </div>
                          </div>
                          <h3>Professional Information</h3>
                          <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 no-gutter">
                              <h2>Role</h2> <h1>{studentRole}</h1>
                            </div>
                            <div className="col-lg-9 col-md-12 col-sm-12 no-gutter">
                              <h2>Favroite Thing You Love</h2>{" "}
                              <div className="skills" style={{ marginTop: '-10px' }}>
                                {" "}
                                <h2>{skills}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: activeTab === "content" ? "block" : "none",
                        }}
                      >
                        <div className="content">
                          <div className="content-head">
                            <div>
                              <h2>{compltedCount} Completed At this time</h2>
                            </div>
                            <div className="content-stats">
                              <div>
                                {" "}
                                <h3>Published</h3>
                              </div>
                              <div>
                                {" "}
                                <h2>Course</h2>
                              </div>
                            </div>
                          </div>
                          <div className="content-cards">
                            <div className="row">
                              {courses?.map((item, index) =>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                  <div className="custom-card">
                                    <div className="card-image">
                                      <img src={img1} alt="Card Image" />
                                    </div>
                                    <div className="card-info-1">
                                      <h3>{item.course.title}</h3>
                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="course-type">
                                          <FontAwesomeIcon
                                            icon={faUsers}
                                            style={{ color: "#FFFFFF99" }}
                                          />{" "}
                                          <h2>{item.status}</h2>
                                        </div>
                                        <div className="course-type">
                                          <FontAwesomeIcon
                                            icon={faLeaf}
                                            style={{ color: "#FFFFFF99" }}
                                          />{" "}
                                          <h2>{item.course.category}</h2>
                                        </div>
                                      </div>
                                      {/* <div className="card-stats">
                                  <div className="row">
                                    <div className="col-6">
                                      <h2>Completion Rate</h2>
                                      <h3>68%</h3>
                                    </div>
                                    <div className="col-6">
                                      <h2>Student Reaction</h2>
                                      <h3>38%</h3>
                                    </div>
                                  </div>
                                </div> */}
                                      <p className="des-csa">{extractFirst22Words1(item.course.description)}</p>
                                      <div className="course-card-button">
                                        <button>Total Statistics</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: activeTab === "groups" ? "block" : "none",
                        }}
                      >
                        <div className="profile-groups">
                          <div className="groups-table">
                            <div className="groups-table-heading">
                              <div className="row">
                                <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                                  <h2>Group Name</h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                                  <h2>Total Members</h2>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                                  <h2>Date Added</h2>
                                </div>
                              </div>
                            </div>
                            <hr className="mt-2" />
                            <div className="row">
                              <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                                <div className="group-name">
                                  <div className="group-name-1">
                                    <img src={img1} alt="" />
                                  </div>
                                  <div className="group-name-2">
                                    <h2>Public</h2> <h3>Course Title</h3>{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                                <div className="group-total-members">
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <div className="member-count">
                                    <h2>+34</h2>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                                <div className="group-date-added">
                                  <h2>August 19, 2023</h2>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                                <div className="group-name">
                                  <div className="group-name-1">
                                    <img src={img1} alt="" />
                                  </div>
                                  <div className="group-name-2">
                                    <h2>Public</h2> <h3>Course Title</h3>{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                                <div className="group-total-members">
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <div className="member-count">
                                    <h2>+34</h2>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                                <div className="group-date-added">
                                  <h2>August 19, 2023</h2>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5 col-md-4 col-sm-4 no-gutter">
                                <div className="group-name">
                                  <div className="group-name-1">
                                    <img src={img1} alt="" />
                                  </div>
                                  <div className="group-name-2">
                                    <h2>Public</h2> <h3>Course Title</h3>{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4 no-gutter">
                                <div className="group-total-members">
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <img src={img1} alt="" />
                                  <div className="member-count">
                                    <h2>+34</h2>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-4 no-gutter">
                                <div className="group-date-added">
                                  <h2>August 19, 2023</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Studentprofiles;
