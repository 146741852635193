import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./addstudent.css"; // Adjust the path based on your project structure
import axios from "axios";
function Addstudentreg() {
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [images, setImages] = useState(null);
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImages(e.target.files[0])
  }
  const [selectedValue, setSelectedValue] = useState('');
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [studentFirstName, setFirstName] = useState('');
  const [studentLastName, setLastName] = useState('');
  const handlestudentFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }
  const handlestudentLastNameChange = (event) => {
    setLastName(event.target.value);
  }
  const [registrationNumber, setregistrationNumber] = useState('');
  const [age, setAge] = useState('');
  const handlestudentRegistrationNumberChange = (e) => {
    setregistrationNumber(e.target.value);
  }
  const handleageChange = (e) => {
    setAge(e.target.value);
  }
  const [selectedGender, setSelectedGender] = useState('');
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const handleemailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleNumberChange = (e) => {
    setNumber(e.target.value)
  }
  const [degree, setDegree] = useState('');
  const [website, setWebsite] = useState('');
  const [education, setEducation] = useState('');
  const [certification, setCertification] = useState('');
  const [about, setAbout] = useState('');
  const [skill, setSkill] = useState('');
  const [expirience, setExpirience] = useState('');
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const handleDegreeChange = (e) => {
    setDegree(e.target.value);
  }
  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  }
  const handleEducationChange = (e) => {
    setEducation(e.target.value);
  }
  const handleCertifcationChange = (e) => {
    setCertification(e.target.value);
  }
  const handleAboutChange = (e) => {
    setAbout(e.target.value);
  }
  const handleSkillChange = (e) => {
    setSkill(e.target.value);
  }
  const handleExpirienceChange = (e) => {
    setExpirience(e.target.value);
  }
  const handleUsernameChange = (e) => {
    setusername(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setpassword(e.target.value);
  }
  const saveStudent = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token")
    try {
      const formData = new FormData();
      formData.append('attachment', images);
      const attachmentResponse = await axios.post('https://api.mastertattooinstitute.com/api/v1/attachment', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const CoverId = attachmentResponse.data.id;
      const formData1 = new FormData();
      formData1.append('firstName', studentFirstName);
      formData1.append('lastName', studentLastName);
      formData1.append('email', email);
      formData1.append('phone', number);
      formData1.append('role', selectedValue);
      formData1.append('age', age);
      formData1.append('experience', expirience);
      formData1.append('certification', certification);
      formData1.append('about', about);
      formData1.append('gender', selectedGender);
      formData1.append('registerationNumber', registrationNumber);
      formData1.append('website', website);
      formData1.append('education', education);
      formData1.append('degree', degree);
      formData1.append('skills', skill);
      formData1.append('profilePicture', CoverId);
      formData1.append('username', username);
      formData1.append('password', password);
      // console.log(image,title,description)
      try {
        const response = await axios.post(
          "https://api.mastertattooinstitute.com/api/v1/user/register",
          formData1, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
        setFirstName('');
        setLastName('');
        setEmail('');
        setNumber('');
        setSelectedValue('');
        setAge('');
        setEducation('');
        setExpirience('');
        setCertification('');
        setAbout('');
        setSelectedGender('');
        setregistrationNumber('');
        setWebsite('');
        setDegree('');
        setSkill('');
        setusername('');
        setpassword('');
        console.log(response.data);
        setValidationMessage('Successfully! Create The User');
        setTimeout(() => {
          setValidationMessage('');
        }, 3000);
      } catch (error) {
        console.error("Error to Create the New User:", error);
        setValidationMessage('Sorry! Cannot Create The User');
        setTimeout(() => {
          setValidationMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error uploading or updating profile picture:', error);
      setValidationMessage('Sorry! Cannot Create The User');
      setTimeout(() => {
        setValidationMessage('');
      }, 3000);
    }
  };
  return (
    <div className="container">
      {validationMessage && (
        <div className={`successm`}>
          <p>{validationMessage}</p>
        </div>
      )}
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="side-menu">
            <ul>
              <div className="back-button-div">
                <Link to="/studentreg">
                  <FontAwesomeIcon
                    className="back-button"
                    icon={faCaretLeft}
                    style={{ color: "#283C55" }}
                  />
                  <h2 style={{ marginTop: '5px' }}>Back to Students List</h2>
                </Link>
              </div>
              <li className="active">
                <Link to="/addstudentreg">Add Manually</Link>
              </li>
              {/* <li>
                <Link to="/addstudentmail">E-mail Invite</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-9 col-md-6 col-sm-12">
          <div className="white-container">
            <div className="add-student-header">
              <h2 className="add-us">Add User</h2>
              <div className="preview-save">
                <div className="preview-btn">
                  <FontAwesomeIcon icon={faEye} style={{ color: "#283C55", marginTop: '-5px' }} />
                  <h3>Preview</h3>
                </div>
                <button onClick={saveStudent}>Save</button>
              </div>
            </div>
            <hr style={{ marginTop: '-10px' }} />
            <div className="container">
              <h2 className="user-ro">User Role</h2>
              <div>
                <input type="radio" value="student" name="student" checked={selectedValue === 'student'}
                  onChange={handleRadioChange} /> Student
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio" value="admin" name="admin" checked={selectedValue === 'admin'}
                  onChange={handleRadioChange} /> Instructor
                {/* <input type="radio" value="admin" name="gender"checked={selectedValue === 'admin'}
          onChange={handleRadioChange} /> Admin */}
              </div>
            </div>
            <div className="profile-picture">
              <h3 className="user-ro">Profile Picture</h3>
              <div className="profile-pic">
                <img src={file} className="upload-image_1" />
                <input type="file" onChange={handleChange} accept="image/*" />
              </div>
            </div>
            <div className="personal-info">
              <h3>Personal Information</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <h2>First Name*</h2>
                    <input type="text" required value={studentFirstName}
                      onChange={handlestudentFirstNameChange} />
                    <h2>Last Name*</h2>
                    <input type="text" required value={studentLastName}
                      onChange={handlestudentLastNameChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Registration Number*</h2>
                    <input type="text" required value={registrationNumber}
                      onChange={handlestudentRegistrationNumberChange} />
                    <h2>Age*</h2>
                    <input type="text" required value={age}
                      onChange={handleageChange} />
                  </div>
                  <div className="col-lg-6 col-md-10 col-sm-10">
                    <h2>Gender*</h2>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        value="Male"
                        name="gender" checked={selectedGender === 'Male'}
                        onChange={handleGenderChange}
                      />{" "}
                      Male
                      <input
                        className="radio"
                        type="radio"
                        value="Female"
                        name="gender"
                        checked={selectedGender === 'Female'}
                        onChange={handleGenderChange}
                      />{" "}
                      Female
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-info">
              <h3>Contact Information</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>E-mail*</h2>
                    <input type="text" required value={email}
                      onChange={handleemailChange} />
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Mobile Number*</h2>
                    <input type="text" required value={number}
                      onChange={handleNumberChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>City*</h2>
                    <input type="text" required />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="educational-info">
              <h3>Hobbies & Interests</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Favorite Movies</h2>
                       <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={degree}
                      onChange={handleDegreeChange}>
                    </textarea>
                    <h2>Favroite Music</h2>
                      <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={education}
                      onChange={handleEducationChange}>
                    </textarea>
                    <h2>About*</h2>
                    <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={about}
                      onChange={handleAboutChange}>
                    </textarea>
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Favroite Sports</h2>
                      <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={website}
                      onChange={handleWebsiteChange}>
                    </textarea>
                    <h2>Favroite Hobbies</h2>
                       <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={certification}
                      onChange={handleCertifcationChange}>
                    </textarea>
                    <h2>Special Interest Groups</h2>
                        <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={expirience}
                      onChange={handleExpirienceChange}>
                    </textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-10">
                    <h2>Favroite Things You Love</h2>
                    <div className="skills">
                        <textarea rows="5"
                      cols="70"
                      name="comment"
                      form="usrform"
                      className="add-student-about"
                      required
                      value={skill}
                      onChange={handleSkillChange}>
                    </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="educational-info">
              <h3>Give Username & Password</h3>
              <div className="">
                <div className="row">
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Username*</h2>
                    <input type="text" required value={username}
                      onChange={handleUsernameChange} />
                  </div>
                  <div className="col-lg-4 col-md-10 col-sm-10">
                    <h2>Password*</h2>
                    <input type="text" required value={password}
                      onChange={handlePasswordChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Addstudentreg;
