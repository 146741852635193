import React, { useState, useEffect } from "react";
import LOGO from "../assets/logo2.png";
import "reactjs-popup/dist/index.css";
import "./contentcourse.css";
import FRAME from "../assets/Frame 3619.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Upernav from "./Upernav";
export default function Quiz() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const [activeTab, setActiveTab] = useState("grid");
  const showUserContent = () => {
    setActiveTab("grid");
  };
  const showContent = () => {
    setActiveTab("list");
  };
  const name = localStorage.getItem("info")
  const [isUlVisible, setIsUlVisible] = useState(false);
  const toggleUlVisibility = () => {
    setIsUlVisible(!isUlVisible);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [courses, setCourses] = useState([]);
  const [weekslen, setLenght] = useState([]);
  const [pic, setPic] = useState([null]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Make the API request using fetch
    const token1 = localStorage.getItem("token");
    fetch('https://api.mastertattooinstitute.com/api/v1/course', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setCourses(data);
        setLenght(data.length);
        setPic(data.cover.fileName)
        console.log(data)
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
  const extractFirst22Words = (paragraph) => {
    return paragraph.slice(0, 14);
  };
  const extractFirst22Words1 = (paragraph) => {
    return paragraph.slice(0, 30);
  };
  return (
    <div>
      <div className="body-background">
        {loading ? (
          <div className="kkw">
            <span class="loaders"></span>
          </div>
        ) : (
          <div className="full-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="fixer">
                    <div className="data">
                      <img src={LOGO} />
                    </div>
                    <div className="leftnav">
                      <button onClick={toggleUlVisibility}>
                        <FontAwesomeIcon
                          className="bars"
                          icon={faBars}
                          style={{ color: "#283C55" }}
                        />
                      </button>
                      <ul
                        className="leftnav-1 padder"
                        style={{ display: isUlVisible ? "none" : "block" }}
                      >
                        <li>
                          <Link to="/welcomepage">
                            <FontAwesomeIcon
                              icon={faTableColumns}
                              style={{ color: "#283C55", marginBottom: "10px" }}
                            />
                            <h2>Dashboard</h2>
                          </Link>
                        </li>
                        <li>
                        <Link to={'/inbox'}>
                          <FontAwesomeIcon
                            icon={faInbox}
                            style={{ color: "#283C55", marginBottom: "10px" }}
                          />
                          <h2>Inbox</h2>
                        </Link>
                      </li>
                        <hr />
                        <li>
                          <Link to="/content" className="isactive">
                            <FontAwesomeIcon
                              icon={faClipboard}
                              style={{ color: "#283C55", marginBottom: "10px" }}
                            />
                            <h2>Content</h2>
                          </Link>
                          <ul className="sub-tabs-list">
                            <li className="sub-tab">
                              <Link to="/content">Courses</Link>
                            </li>
                            <li className="sub-tab isactive1">
                              <Link to="/quiz">Quiz</Link>
                            </li>
                            <li className="sub-tab">
                              <Link to="/addnewassignment">Assignments</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/studentsp">
                            <FontAwesomeIcon
                              icon={faSearch}
                              style={{ color: "#283C55" }}
                            />
                            <h2>Explore Student Portfolio</h2>
                          </Link>
                        </li>
                        <li>
                          <Link to="/studentreg">
                            <FontAwesomeIcon
                              icon={faUsers}
                              style={{ color: "#283C55" }}
                            />
                            <h2>Users</h2>
                          </Link>
                        </li>
                      </ul>
                      <ul
                        className="leftnav-2 padder"
                        style={{ display: isUlVisible ? "none" : "block" }}
                      >
                      <li>
                          <Link to={'/help'}>
                              <FontAwesomeIcon
                                  icon={faCircleInfo}
                                  style={{ color: "#283C55" }}
                              />
                              <h2>Help</h2>
                          </Link>
                      </li>
                      <li>
                          <Link to={'/settings'}>
                              <FontAwesomeIcon
                                  icon={faGear}
                                  style={{ color: "#283C55" }}
                              />
                              <h2>Settings</h2>
                          </Link>
                      </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <div className="div-style">
                    <div className="container">
                      <Upernav />
                    </div>
                    <div className="container-fuild">
                      <p style={{ borderBottom: '1px solid rgba(40, 60, 85, 0.40)', marginBottom: '5px', marginTop: '5px' }}></p>
                    </div>
                    <div className="container back">
                      <div className="add-course">
                        <h2 className="totalcourse">
                          <span className="numbers1">{weekslen}</span>&nbsp;Courses In Total
                        </h2>
                        <div className="mmixer">
                          <div className="status">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_875_552)">
                                <path
                                  d="M4 8L7 10.5L11 5.5M7.5 15C6.58075 15 5.6705 14.8189 4.82122 14.4672C3.97194 14.1154 3.20026 13.5998 2.55025 12.9497C1.90024 12.2997 1.38463 11.5281 1.03284 10.6788C0.68106 9.82951 0.5 8.91925 0.5 8C0.5 7.08075 0.68106 6.1705 1.03284 5.32122C1.38463 4.47194 1.90024 3.70026 2.55025 3.05025C3.20026 2.40024 3.97194 1.88463 4.82122 1.53284C5.6705 1.18106 6.58075 1 7.5 1C9.35652 1 11.137 1.7375 12.4497 3.05025C13.7625 4.36301 14.5 6.14348 14.5 8C14.5 9.85652 13.7625 11.637 12.4497 12.9497C11.137 14.2625 9.35652 15 7.5 15Z"
                                  stroke="#283C55"
                                  stroke-opacity="0.5"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_875_552">
                                  <rect
                                    width="15"
                                    height="15"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <h2 className="all-status">&nbsp;All Status</h2>
                          </div>
                          {/* <div className="filter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="10"
                          viewBox="0 0 16 10"
                          fill="none"
                          className="em"
                        >
                          <path
                            d="M5.25 8.9375C5.25 8.76345 5.31914 8.59653 5.44221 8.47346C5.56528 8.35039 5.7322 8.28125 5.90625 8.28125H9.84375C10.0178 8.28125 10.1847 8.35039 10.3078 8.47346C10.4309 8.59653 10.5 8.76345 10.5 8.9375C10.5 9.11155 10.4309 9.27847 10.3078 9.40154C10.1847 9.52461 10.0178 9.59375 9.84375 9.59375H5.90625C5.7322 9.59375 5.56528 9.52461 5.44221 9.40154C5.31914 9.27847 5.25 9.11155 5.25 8.9375ZM2.625 5C2.625 4.82595 2.69414 4.65903 2.81721 4.53596C2.94028 4.41289 3.1072 4.34375 3.28125 4.34375H12.4688C12.6428 4.34375 12.8097 4.41289 12.9328 4.53596C13.0559 4.65903 13.125 4.82595 13.125 5C13.125 5.17405 13.0559 5.34097 12.9328 5.46404C12.8097 5.58711 12.6428 5.65625 12.4688 5.65625H3.28125C3.1072 5.65625 2.94028 5.58711 2.81721 5.46404C2.69414 5.34097 2.625 5.17405 2.625 5ZM0 1.0625C0 0.888452 0.0691406 0.721532 0.192211 0.598461C0.315282 0.475391 0.482202 0.40625 0.65625 0.40625H15.0938C15.2678 0.40625 15.4347 0.475391 15.5578 0.598461C15.6809 0.721532 15.75 0.888452 15.75 1.0625C15.75 1.23655 15.6809 1.40347 15.5578 1.52654C15.4347 1.64961 15.2678 1.71875 15.0938 1.71875H0.65625C0.482202 1.71875 0.315282 1.64961 0.192211 1.52654C0.0691406 1.40347 0 1.23655 0 1.0625Z"
                            fill="#283C55"
                            fill-opacity="0.5"
                          />
                        </svg>
                        <h2 className="all-status">&nbsp;Filter</h2>
                      </div> */}
                          {/* <div className="sortr">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          className="emr"
                        >
                          <path
                            d="M4 14H0L6 20V0H4V14ZM10 3V20H12V6H16L10 0V3Z"
                            fill="#283C55"
                            fill-opacity="0.5"
                          />
                        </svg>
                        <h2 className="all-status">&nbsp;Sort</h2>
                      </div> */}
                          <div div className={activeTab === "list" ? "active-tab" : ""}>
                            <div className="bullet" style={{ cursor: 'pointer' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                className="ew1"
                                onClick={showContent}
                              >
                                <path
                                  d="M12.0013 26.6654H26.668C27.4013 26.6654 28.0293 26.404 28.552 25.8814C29.0746 25.3587 29.3355 24.7311 29.3346 23.9987V21.332H12.0013V26.6654ZM2.66797 10.6654H9.33464V5.33203H5.33464C4.6013 5.33203 3.9733 5.59337 3.45064 6.11603C2.92797 6.6387 2.66708 7.26626 2.66797 7.9987V10.6654ZM2.66797 18.6654H9.33464V13.332H2.66797V18.6654ZM5.33464 26.6654H9.33464V21.332H2.66797V23.9987C2.66797 24.732 2.9293 25.36 3.45197 25.8827C3.97464 26.4054 4.60219 26.6663 5.33464 26.6654ZM12.0013 18.6654H29.3346V13.332H12.0013V18.6654ZM12.0013 10.6654H29.3346V7.9987C29.3346 7.26537 29.0733 6.63737 28.5506 6.1147C28.028 5.59203 27.4004 5.33114 26.668 5.33203H12.0013V10.6654Z"
                                  fill="#283C55"
                                />
                              </svg>
                            </div>
                          </div>
                          <div div className={activeTab === "list" ? "active-tab" : ""}>
                            <div className="border-grid bullet1" style={{ cursor: 'pointer' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                className="ew"
                                onClick={showUserContent}
                              >
                                <path
                                  d="M1.75 4.375C1.75 3.67881 2.02656 3.01113 2.51884 2.51884C3.01113 2.02656 3.67881 1.75 4.375 1.75H9.625C10.3212 1.75 10.9889 2.02656 11.4812 2.51884C11.9734 3.01113 12.25 3.67881 12.25 4.375V9.625C12.25 10.3212 11.9734 10.9889 11.4812 11.4812C10.9889 11.9734 10.3212 12.25 9.625 12.25H4.375C3.67881 12.25 3.01113 11.9734 2.51884 11.4812C2.02656 10.9889 1.75 10.3212 1.75 9.625V4.375ZM15.75 4.375C15.75 3.67881 16.0266 3.01113 16.5188 2.51884C17.0111 2.02656 17.6788 1.75 18.375 1.75H23.625C24.3212 1.75 24.9889 2.02656 25.4812 2.51884C25.9734 3.01113 26.25 3.67881 26.25 4.375V9.625C26.25 10.3212 25.9734 10.9889 25.4812 11.4812C24.9889 11.9734 24.3212 12.25 23.625 12.25H18.375C17.6788 12.25 17.0111 11.9734 16.5188 11.4812C16.0266 10.9889 15.75 10.3212 15.75 9.625V4.375ZM1.75 18.375C1.75 17.6788 2.02656 17.0111 2.51884 16.5188C3.01113 16.0266 3.67881 15.75 4.375 15.75H9.625C10.3212 15.75 10.9889 16.0266 11.4812 16.5188C11.9734 17.0111 12.25 17.6788 12.25 18.375V23.625C12.25 24.3212 11.9734 24.9889 11.4812 25.4812C10.9889 25.9734 10.3212 26.25 9.625 26.25H4.375C3.67881 26.25 3.01113 25.9734 2.51884 25.4812C2.02656 24.9889 1.75 24.3212 1.75 23.625V18.375ZM15.75 18.375C15.75 17.6788 16.0266 17.0111 16.5188 16.5188C17.0111 16.0266 17.6788 15.75 18.375 15.75H23.625C24.3212 15.75 24.9889 16.0266 25.4812 16.5188C25.9734 17.0111 26.25 17.6788 26.25 18.375V23.625C26.25 24.3212 25.9734 24.9889 25.4812 25.4812C24.9889 25.9734 24.3212 26.25 23.625 26.25H18.375C17.6788 26.25 17.0111 25.9734 16.5188 25.4812C16.0266 24.9889 15.75 24.3212 15.75 23.625V18.375Z"
                                  fill="#283C55"
                                  fill-opacity="0.4"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid" style={{ display: activeTab === "grid" ? "block" : "none" }}>
                        <div className="row">
                          {courses?.map(course => (
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card">
                                {course?.cover?.fileName ? (
                                  // Render the profile picture if fileName is not null
                                  <img src={'https://api.mastertattooinstitute.com/' + course.cover.fileName} className="card-img-top1" alt="Profile" />
                                ) : (
                                  // Render another picture or a default image when fileName is null
                                  <img src={FRAME} class="card-img-top1" alt="Default" />
                                )}{" "}
                                {/* <img src={FRAME} class="card-img-top" alt="course picture" /> */}
                                {/* <img src={'http://195.35.37.213:1337/'+course.cover.fileName} class="card-img-top" alt="course picture" /> */}
                                <div class="card-body">
                                  <h5 class="card-title">{course.title}</h5>
                                  <p class="card-text">
                                    <div className="cards12">
                                      <div className="ghjll">
                                        <div className="status">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="14"
                                            viewBox="0 0 15 14"
                                            fill="none"
                                          >
                                            <g clip-path="url(#clip0_630_2840)">
                                              <path
                                                d="M9.69048 5.27493C9.69048 5.96323 9.41705 6.62333 8.93035 7.11004C8.44365 7.59674 7.78354 7.87016 7.09524 7.87016C6.40694 7.87016 5.74683 7.59674 5.26013 7.11004C4.77343 6.62333 4.5 5.96323 4.5 5.27493C4.5 4.58663 4.77343 3.92652 5.26013 3.43982C5.74683 2.95311 6.40694 2.67969 7.09524 2.67969C7.78354 2.67969 8.44365 2.95311 8.93035 3.43982C9.41705 3.92652 9.69048 4.58663 9.69048 5.27493Z"
                                                fill="#283C55"
                                                fill-opacity="0.6"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0.175781 7.00267C0.175781 5.1672 0.904918 3.40691 2.20279 2.10904C3.50066 0.811168 5.26095 0.0820313 7.09642 0.0820312C8.93188 0.0820313 10.6922 0.811168 11.99 2.10904C13.2879 3.40691 14.0171 5.1672 14.0171 7.00267C14.0171 8.83813 13.2879 10.5984 11.99 11.8963C10.6922 13.1942 8.93188 13.9233 7.09642 13.9233C5.26095 13.9233 3.50066 13.1942 2.20279 11.8963C0.904918 10.5984 0.175781 8.83813 0.175781 7.00267ZM7.09642 0.947111C5.95605 0.947171 4.83888 1.26923 3.87348 1.87623C2.90808 2.48323 2.1337 3.35048 1.63946 4.37818C1.14523 5.40588 0.951227 6.55225 1.07979 7.68535C1.20835 8.81845 1.65425 9.89221 2.36616 10.7831C2.98037 9.79341 4.33249 8.73282 7.09642 8.73282C9.86034 8.73282 11.2116 9.79255 11.8267 10.7831C12.5386 9.89221 12.9845 8.81845 13.113 7.68535C13.2416 6.55225 13.0476 5.40588 12.5534 4.37818C12.0591 3.35048 11.2848 2.48323 10.3194 1.87623C9.35396 1.26923 8.23678 0.947171 7.09642 0.947111Z"
                                                fill="#283C55"
                                                fill-opacity="0.6"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_630_2840">
                                                <rect
                                                  width="13.8413"
                                                  height="13.8413"
                                                  fill="white"
                                                  transform="translate(0.175781 0.0820312)"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                          <h2 className="all-status">
                                            &nbsp;{course.weeks.length} weeks
                                          </h2>
                                        </div>
                                        <div className="status">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="15"
                                            viewBox="0 0 14 15"
                                            fill="none"
                                          >
                                            <path
                                              d="M0.802347 13.2752L5.28454 8.79298M10.9288 6.7998L9.58714 10.8249C9.56573 10.8894 9.52907 10.9478 9.48027 10.9952C9.43147 11.0425 9.37198 11.0773 9.30683 11.0968L1.08196 13.5414C1.00786 13.5634 0.92914 13.5647 0.854374 13.545C0.779607 13.5254 0.711655 13.4857 0.657913 13.4301C0.60417 13.3746 0.566695 13.3053 0.549567 13.2299C0.532439 13.1546 0.536314 13.0759 0.56077 13.0026L3.23149 4.99184C3.25064 4.93433 3.28193 4.8816 3.32325 4.83724C3.36456 4.79289 3.41494 4.75794 3.47096 4.73477L7.26505 3.17263C7.34218 3.14077 7.42702 3.13246 7.50887 3.14876C7.59072 3.16505 7.66591 3.20522 7.72496 3.26419L10.8267 6.36665C10.8823 6.4222 10.9214 6.49217 10.9394 6.56869C10.9575 6.64521 10.9538 6.72524 10.9288 6.7998Z"
                                              stroke="#283C55"
                                              stroke-opacity="0.6"
                                              stroke-width="1.05646"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M11.0136 6.55172L13.0054 4.55924C13.2695 4.29509 13.4178 3.93687 13.4178 3.56335C13.4178 3.18984 13.2695 2.83162 13.0054 2.56747L11.5123 1.07293C11.3814 0.942068 11.2261 0.83826 11.0552 0.767435C10.8843 0.69661 10.701 0.660156 10.516 0.660156C10.331 0.660156 10.1478 0.69661 9.97683 0.767435C9.80589 0.83826 9.65058 0.942068 9.51977 1.07293L7.528 3.06541M6.78002 7.29828C6.68189 7.2002 6.5654 7.1224 6.4372 7.06933C6.309 7.01627 6.17161 6.98897 6.03286 6.989C5.89411 6.98904 5.75673 7.0164 5.62856 7.06952C5.50038 7.12265 5.38393 7.2005 5.28584 7.29864C5.18776 7.39677 5.10996 7.51326 5.05689 7.64146C5.00383 7.76966 4.97653 7.90705 4.97656 8.0458C4.9766 8.18455 5.00396 8.32193 5.05708 8.4501C5.11021 8.57828 5.18806 8.69473 5.28619 8.79282C5.48438 8.99091 5.75314 9.10216 6.03336 9.1021C6.31357 9.10203 6.58228 8.99065 6.78038 8.79247C6.97847 8.59428 7.08972 8.32552 7.08966 8.0453C7.08959 7.76509 6.97821 7.49638 6.78002 7.29828Z"
                                              stroke="#283C55"
                                              stroke-opacity="0.6"
                                              stroke-width="1.05646"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                          <h2 className="all-status">&nbsp;{extractFirst22Words(course.category)}</h2>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="line1"></p>
                                    <p className="coure-des1">{extractFirst22Words1(course.description)}</p>
                                    {/* <div className="cards23">
                                <div className="completion">
                                  <h2 className="complete">
                                    Completion Rate
                                    <span className="percent">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_630_2818)">
                                          <path
                                            d="M4 7.5L7 10L11 5M7.5 14.5C6.58075 14.5 5.6705 14.3189 4.82122 13.9672C3.97194 13.6154 3.20026 13.0998 2.55025 12.4497C1.90024 11.7997 1.38463 11.0281 1.03284 10.1788C0.68106 9.32951 0.5 8.41925 0.5 7.5C0.5 6.58075 0.68106 5.6705 1.03284 4.82122C1.38463 3.97194 1.90024 3.20026 2.55025 2.55025C3.20026 1.90024 3.97194 1.38463 4.82122 1.03284C5.6705 0.68106 6.58075 0.5 7.5 0.5C9.35652 0.5 11.137 1.2375 12.4497 2.55025C13.7625 3.86301 14.5 5.64348 14.5 7.5C14.5 9.35652 13.7625 11.137 12.4497 12.4497C11.137 13.7625 9.35652 14.5 7.5 14.5Z"
                                            stroke="#283C55"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_630_2818">
                                            <rect
                                              width="15"
                                              height="15"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      &nbsp;&nbsp;68%
                                    </span>
                                  </h2>
                                </div>
                                <p className="line2"></p>
                                <div className="completion">
                                  <h2 className="complete">
                                    Student Reaction
                                    <span className="percent">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_630_2814)">
                                          <path
                                            d="M7.49944 14.4637C9.34649 14.4637 11.1179 13.73 12.4239 12.4239C13.73 11.1179 14.4637 9.34649 14.4637 7.49944C14.4637 5.6524 13.73 3.88101 12.4239 2.57495C11.1179 1.26889 9.34649 0.535156 7.49944 0.535156C5.6524 0.535156 3.88101 1.26889 2.57495 2.57495C1.26889 3.88101 0.535156 5.6524 0.535156 7.49944C0.535156 9.34649 1.26889 11.1179 2.57495 12.4239C3.88101 13.73 5.6524 14.4637 7.49944 14.4637Z"
                                            stroke="#283C55"
                                            stroke-width="0.785714"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M3.96484 8.57254C4.50056 10.5011 6.64342 11.6797 8.57199 11.144C9.75056 10.7154 10.7148 9.75112 11.0363 8.57254M5.14342 5.8404C5.07238 5.8404 5.00424 5.81218 4.95401 5.76195C4.90378 5.71172 4.87556 5.64358 4.87556 5.57254C4.87556 5.5015 4.90378 5.43337 4.95401 5.38314C5.00424 5.33291 5.07238 5.30469 5.14342 5.30469C5.21446 5.30469 5.28259 5.33291 5.33282 5.38314C5.38305 5.43337 5.41127 5.5015 5.41127 5.57254C5.41127 5.64358 5.38305 5.71172 5.33282 5.76195C5.28259 5.81218 5.21446 5.8404 5.14342 5.8404ZM9.8577 5.8404C9.78666 5.8404 9.71853 5.81218 9.6683 5.76195C9.61806 5.71172 9.58984 5.64358 9.58984 5.57254C9.58984 5.5015 9.61806 5.43337 9.6683 5.38314C9.71853 5.33291 9.78666 5.30469 9.8577 5.30469C9.92874 5.30469 9.99687 5.33291 10.0471 5.38314C10.0973 5.43337 10.1256 5.5015 10.1256 5.57254C10.1256 5.64358 10.0973 5.71172 10.0471 5.76195C9.99687 5.81218 9.92874 5.8404 9.8577 5.8404Z"
                                            stroke="#283C55"
                                            stroke-width="0.785714"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_630_2814">
                                            <rect
                                              width="15"
                                              height="15"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      &nbsp;68% Useful
                                    </span>
                                  </h2>
                                </div>
                              </div> */}
                                    <p className="line1"></p>
                                  </p>
                                  <Link
                                    to={`/addnewquiz/${course.id}`}
                                    class="btn btn-primary bt"
                                  >
                                    <i class="fa-regular fa-clock"></i>Quiz
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="container" style={{
                        display: activeTab === "list" ? "block" : "none",
                      }}>
                        {courses?.map(course => (
                          <div className="list_view">
                            <div className="img-tag">
                              {course?.cover?.fileName ? (
                                // Render the profile picture if fileName is not null
                                <img src={'https://api.mastertattooinstitute.com/' + course.cover.fileName} className="list-img1" alt="Profile" />
                              ) : (
                                // Render another picture or a default image when fileName is null
                                <img src={FRAME} className="list-img1" alt="Default" />
                              )}{" "}
                            </div>
                            <div className="description_tag">
                              <h2 className="list_title">CourseTitle-{course.title}</h2>
                              <div className="list-flex">
                                <div className="status">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_630_2840)">
                                      <path
                                        d="M9.69048 5.27493C9.69048 5.96323 9.41705 6.62333 8.93035 7.11004C8.44365 7.59674 7.78354 7.87016 7.09524 7.87016C6.40694 7.87016 5.74683 7.59674 5.26013 7.11004C4.77343 6.62333 4.5 5.96323 4.5 5.27493C4.5 4.58663 4.77343 3.92652 5.26013 3.43982C5.74683 2.95311 6.40694 2.67969 7.09524 2.67969C7.78354 2.67969 8.44365 2.95311 8.93035 3.43982C9.41705 3.92652 9.69048 4.58663 9.69048 5.27493Z"
                                        fill="#283C55"
                                        fill-opacity="0.6"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.175781 7.00267C0.175781 5.1672 0.904918 3.40691 2.20279 2.10904C3.50066 0.811168 5.26095 0.0820313 7.09642 0.0820312C8.93188 0.0820313 10.6922 0.811168 11.99 2.10904C13.2879 3.40691 14.0171 5.1672 14.0171 7.00267C14.0171 8.83813 13.2879 10.5984 11.99 11.8963C10.6922 13.1942 8.93188 13.9233 7.09642 13.9233C5.26095 13.9233 3.50066 13.1942 2.20279 11.8963C0.904918 10.5984 0.175781 8.83813 0.175781 7.00267ZM7.09642 0.947111C5.95605 0.947171 4.83888 1.26923 3.87348 1.87623C2.90808 2.48323 2.1337 3.35048 1.63946 4.37818C1.14523 5.40588 0.951227 6.55225 1.07979 7.68535C1.20835 8.81845 1.65425 9.89221 2.36616 10.7831C2.98037 9.79341 4.33249 8.73282 7.09642 8.73282C9.86034 8.73282 11.2116 9.79255 11.8267 10.7831C12.5386 9.89221 12.9845 8.81845 13.113 7.68535C13.2416 6.55225 13.0476 5.40588 12.5534 4.37818C12.0591 3.35048 11.2848 2.48323 10.3194 1.87623C9.35396 1.26923 8.23678 0.947171 7.09642 0.947111Z"
                                        fill="#283C55"
                                        fill-opacity="0.6"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_630_2840">
                                        <rect
                                          width="13.8413"
                                          height="13.8413"
                                          fill="white"
                                          transform="translate(0.175781 0.0820312)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <h2 className="all-status">
                                    &nbsp;{course.weeks.length} weeks
                                  </h2>
                                </div>
                                <div className="status">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.802347 13.2752L5.28454 8.79298M10.9288 6.7998L9.58714 10.8249C9.56573 10.8894 9.52907 10.9478 9.48027 10.9952C9.43147 11.0425 9.37198 11.0773 9.30683 11.0968L1.08196 13.5414C1.00786 13.5634 0.92914 13.5647 0.854374 13.545C0.779607 13.5254 0.711655 13.4857 0.657913 13.4301C0.60417 13.3746 0.566695 13.3053 0.549567 13.2299C0.532439 13.1546 0.536314 13.0759 0.56077 13.0026L3.23149 4.99184C3.25064 4.93433 3.28193 4.8816 3.32325 4.83724C3.36456 4.79289 3.41494 4.75794 3.47096 4.73477L7.26505 3.17263C7.34218 3.14077 7.42702 3.13246 7.50887 3.14876C7.59072 3.16505 7.66591 3.20522 7.72496 3.26419L10.8267 6.36665C10.8823 6.4222 10.9214 6.49217 10.9394 6.56869C10.9575 6.64521 10.9538 6.72524 10.9288 6.7998Z"
                                      stroke="#283C55"
                                      stroke-opacity="0.6"
                                      stroke-width="1.05646"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M11.0136 6.55172L13.0054 4.55924C13.2695 4.29509 13.4178 3.93687 13.4178 3.56335C13.4178 3.18984 13.2695 2.83162 13.0054 2.56747L11.5123 1.07293C11.3814 0.942068 11.2261 0.83826 11.0552 0.767435C10.8843 0.69661 10.701 0.660156 10.516 0.660156C10.331 0.660156 10.1478 0.69661 9.97683 0.767435C9.80589 0.83826 9.65058 0.942068 9.51977 1.07293L7.528 3.06541M6.78002 7.29828C6.68189 7.2002 6.5654 7.1224 6.4372 7.06933C6.309 7.01627 6.17161 6.98897 6.03286 6.989C5.89411 6.98904 5.75673 7.0164 5.62856 7.06952C5.50038 7.12265 5.38393 7.2005 5.28584 7.29864C5.18776 7.39677 5.10996 7.51326 5.05689 7.64146C5.00383 7.76966 4.97653 7.90705 4.97656 8.0458C4.9766 8.18455 5.00396 8.32193 5.05708 8.4501C5.11021 8.57828 5.18806 8.69473 5.28619 8.79282C5.48438 8.99091 5.75314 9.10216 6.03336 9.1021C6.31357 9.10203 6.58228 8.99065 6.78038 8.79247C6.97847 8.59428 7.08972 8.32552 7.08966 8.0453C7.08959 7.76509 6.97821 7.49638 6.78002 7.29828Z"
                                      stroke="#283C55"
                                      stroke-opacity="0.6"
                                      stroke-width="1.05646"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <h2 className="all-status">&nbsp;{course.category}</h2>
                                </div>
                              </div>
                              <p className="coure-des1">{extractFirst22Words1(course.description)}</p>
                              <Link
                                to={`/coursepreview/${course.id}`}
                                class="btn btn-primary bt"
                              >
                                <i class="fa-regular fa-clock"></i>Assign
                              </Link>
                            </div>
                          </div>
                        ))}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
